@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

@font-face {
  font-family: "HWT Artz";
  src: url("../src/assets/fonts/Artz/65923d225f2e78842d14d51023161b8e.eot");
  src: url("../src/assets/fonts/Artz/65923d225f2e78842d14d51023161b8e.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/Artz/65923d225f2e78842d14d51023161b8e.woff2")
      format("woff2"),
    url("../src/assets/fonts/Artz/65923d225f2e78842d14d51023161b8e.woff")
      format("woff"),
    url("../src/assets/fonts/Artz/65923d225f2e78842d14d51023161b8e.ttf")
      format("truetype");
}

form.needs-validation .row select ~ span {
  color: #e52727;
}

.page-body-wrapper {
  background-color: #f3f3f3 !important;
}

body,
div,
p {
  /* color: #2f2f2f;*/
  color: #6d6d6f;
}

.card {
  box-shadow: 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px #0007161f !important;
  border-radius: 0 !important;
}

form .form-control {
  height: 34px !important;
  border: 1px solid #7d8998;
  border-radius: 8px !important;
  color: #000716 !important;
  font-size: 14px !important;
}

textarea.form-control {
  height: initial !important;
}

.card-block.row .table-responsive thead {
  background-color: #000 !important;
  line-height: 12px !important;
  border-top: 2px solid #efefef;
}

tr {
  border-bottom: 1px solid #e9ebed;
}

thead td {
  color: #fff !important;
  font-size: 12px !important;
}

.table-striped-columns > :not(caption) > tr > :nth-child(2n),
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: rgb(255 255 255) !important;
  color: var(--bs-table-striped-color) !important;
  background: #fff !important;
}

.customizer-links {
  display: none !important;
}

form h6 {
  color: #2d85c3 !important;
  font-size: 17px !important;
  font-weight: 900;
  margin-top: 2% !important;
  text-transform: uppercase;
}

form label {
  color: #000716 !important;
  font-size: 14px !important;
  font-weight: 600;
  margin-top: 2% !important;
}

/* .container-fluid {
    border-top: 20px solid #f2f4fb;
} */
select.form-control.form-select {
  font-weight: 600 !important;
  color: #747474 !important;
  font-size: 13px !important;
}

::-webkit-input-placeholder {
  /* Edge */
  font-weight: 600 !important;
  color: #747474 !important;
  font-size: 13px !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 600 !important;
  color: #747474 !important;
  font-size: 13px !important;
}

::placeholder {
  font-weight: 600 !important;
  color: #747474 !important;
  font-size: 13px !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  span {
  letter-spacing: 0.5px !important;
  color: #393d46 !important;
  font-weight: 400 !important;
}

button.btn.btn-primary {
  background: #18679f !important;
}

.table th {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 6px !important;
}

tr td,
.table-striped-columns > :not(caption) > tr > :nth-child(2n),
.table-striped > tbody > tr:nth-of-type(odd) > * {
  padding: 10px 0px 10px 8px !important;
  font-size: 13px !important;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: #e9ebed !important;
}

.crypto-chart.card h6 {
  text-align: right;
  padding-right: 5%;
  font-size: 40px;
  font-weight: 700;
}

h5.font-primary {
  color: #374558 !important;
}

/* .project-list span.switch-state.bg-success {
    height: 24px !important;
}
.project-list .switch-state::before {
    height: 17px !important;
}
.project-list .icon-state .switch-state:after {
    top: 6px !important;
} */

.login-form .form-control {
  height: inherit !important;
  border-radius: inherit !important;
}

.login-card .card {
  width: 980px;
}

.login-card .pricing-content .card {
  width: initial;
}

.project-list .media-body h4 {
  color: #18679f !important;
  font-weight: 800 !important;
}

.activestatus {
  color: #0972d3;
  float: right;
  font-size: 15px;
  text-align: left;
  font-weight: 500;
  padding: 30px !important;
  margin-bottom: -9%;
  text-transform: uppercase !important;
  font-family: "Varela Round" !important;
}

.ecommerce-dash .badge {
  padding: 8px 10px;
  font-size: 10px;
  position: absolute;
  left: 0;
  top: 0;
}

.inactivestatus {
  background: red;
  color: #fff;
  padding: 2%;
  float: right;
  text-align: center;
}

.static-widget {
  margin-bottom: 0px !important;
}

.modal-backdrop {
  --bs-backdrop-bg: #00000014 !important;
}

/* .loader-boxs{
    position: absolute;
    opacity: var(--bs-backdrop-opacity);
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
} */

.loader-boxs {
  position: absolute;
  top: 30%;
  opacity: 2;
  background-blend-mode: overlay;
  background-color: #00000045;
  width: 100%;
}

/* .rbt-input-multi.form-control.rbt-input {
    height: fit-content !important;
    width: fit-content !important;
} */

.zeroBorder {
  border: 0px !important;
}

td .btn {
  float: left !important;
}

.pac-container {
  z-index: 9999;
}

.wrapperCss {
  width: 20vw !important;
  margin: 35px auto 35px;
}

.page-body {
  /* background-image: url("/src/assets/images/Background.png") !important; */
  background-repeat: no-repeat !important;
  /* background-size: contain !important; */
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  /* background: #fff; */
  box-shadow: 2px 5px 8px 0 rgb(0 0 0 / 14%) !important;
}

.page-wrapper .page-header .header-wrapper {
  /* background-color: #000716 !important; */
  padding: 19px 30px !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  margin-left: 280px;
  margin-top: 0px !important;
  padding-top: 61px !important;
  min-height: calc(100vh - 72px);
  background: #f3f3f3 !important;
}

.page-wrapper .page-body-wrapper .page-title {
  padding: 30px 0px 20px 0px !important;
}

.page-wrapper .page-body-wrapper .page-title .h3,
.page-wrapper .page-body-wrapper .page-title h3 {
  font-size: 32px !important;
  text-transform: uppercase !important;
  font-weight: 800 !important;
  color: #000;
  /* color: #e9ebed; */
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .logo-wrapper
  img,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .logo-icon-wrapper
  img {
  height: auto !important;
}

.card .card-header h5,
.card .card-header .h5 {
  font-size: 18px !important;
  font-weight: 500;
}

.custom-card.card {
  /* box-shadow: none !important; */
}

.project-list .media-body h4 {
  color: #000716 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

.page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item {
  color: #2d85c3;
  color: var(--theme-default);
  font-weight: 500 !important;
}

.page-wrapper .page-body-wrapper .page-title .breadcrumb {
  justify-content: flex-start !important;
  margin-bottom: 3px !important;
}

.errorSubscription {
  background: rgb(243, 240, 240);
  color: red;
  padding: 2%;
  text-align: "center";
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .simplebar-wrapper
  .simplebar-mask
  .simplebar-content-wrapper
  .simplebar-content
  > li
  .sidebar-submenu
  li
  a {
  font-weight: 500 !important;
}

.container-fluid {
  color: #fff;
}

.date-range-picker.row {
  font-size: 40px;
  font-weight: 900;
}

.card .card-header .h5,
.card .card-header h5 {
  font-weight: 700;
  font-size: 18px !important;
}

.card .card-header .h5,
.card .card-header h5 {
  font-weight: 700;
  margin-left: -2%;
}

.profile-media.media span,
.greeting-user h1 {
  color: #203450;
  text-transform: capitalize;
  font-weight: 600 !important;
}

button.btn.btn-primary {
  margin-right: 8px;
}

form .form-control {
  border: 1px solid #bfbfbf;
  border-radius: 0px !important;
}

form.needs-validation .row input ~ span {
  color: #e52727;
  margin-left: 7px;
  font-size: 12px;
  font-weight: 200;
}

form.needs-validation .row div ~ span {
  color: #e52727;
  margin-left: 7px;
  font-size: 12px;
  font-weight: 200;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 12px !important;
  font-weight: 200;
  font-family: "Varela Round" !important;
}

button svg {
  vertical-align: middle !important;
  height: 16px;
}

.page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a {
  background-color: transparent !important;
}

.Toastify__toast div {
  color: #fff !important;
}

.grayed-out {
  opacity: 0.1 !important;
  pointer-events: none !important;
  background-color: #f5f5f5 !important;
  height: 100% !important;
}

body .login-card {
  background-image: url("../src/assets/images/loginbg.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 173px;
}

.login-card .logo-wrapper,
.login-card1 .logo-wrapper {
  width: 100px;
  margin: auto;
  margin-bottom: 5%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "HWT Artz";
}

.sidebar-link,
.sidebar-submenu a,
table,
body,
label,
input {
  /* font-family: 'Prompt', sans-serif; */
  /*font-family: "Ubuntu", sans-serif;*/
  font-family: "Varela Round" !important;
}

/* .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
  height: calc(100vh) !important;
} */
form h6 {
  color: #202020 !important;
}

.login-card .btn-block.btn.btn-primary,
.login-card1 .btn-block.btn.btn-primary {
  background: #202020 !important;
  width: 100%;
  margin-top: 8%;
  border-radius: 2px;
  border: none !important;
  font-size: 12px !important;
  font-weight: 200;
  padding: 10px;
}

button.btn.btn-primary {
  /* margin-right: 8px;
  background: #202020 !important;
  border-radius: 2px;
  border: none !important;
  font-size: 12px !important;
  font-weight: 200; */
  border-radius: 12px !important;
  border: 1px solid #138ff2 !important;
  background-color: unset !important;
  color: #138ff2 !important;
  padding: 12px 18px 12px 18px !important;
}

button.btn.btn-danger {
  border-radius: 12px !important;
  color: White !important;
  padding: 12px 18px 12px 18px !important;
}

form .form-control {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  color: var(--bs-body-color);
  background-color: #fff;
  background-color: color(srgb 0.9502 0.9502 0.955 / 0.17);
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 3px !important;
}

.login-card1 .login-form .input-group .input-group-text svg {
  stroke: #202020;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  background: #f8f9fa !important;
}

.page-wrapper .page-header .header-wrapper {
  /* background-color: #202020 !important; */
  padding: 19px 19px !important;
  margin-top: 0px !important;
  background-color: #fff !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  background: #f2f2f3;
}

button.btn-outline-\*.btn-xs.m-r-5.btn.btn-primary {
  width: auto;
  border-radius: 6px !important;
  padding: 1px 6px !important;
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #202020 !important;
}

.btn-danger {
  background-color: #e52727 !important;
  border-color: #e52727 !important;
  margin-right: 8px;
  /* background: #202020 !important; */
  border-radius: 2px !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: 200 !important;
}

.input-group-text {
  padding: 0.175rem 0.75rem !important;
}

.needs-validation .invalid-span {
  color: color(srgb 0.7495 0.1158 0.1793);
}

pricing-block .pricing-header h2,
.pricing-block .pricing-header .h2 {
  color: #202020 !important;
  font-size: 18px !important;
}

.ribbon-success {
  background: #7dc006;
  font-size: 10px;
}

input[type="checkbox"] {
  margin-right: 4px;
}

.nav-primary .nav-link.active,
.nav-primary .show > .nav-link,
.nav-pills.nav-primary .nav-link.active,
.nav-pills.nav-primary .show > .nav-link {
  background-color: #202020 !important;
  color: #fff;
  border-radius: 2px !important;
  font-size: 12px;
  margin-bottom: 20px;
}

.assetAdd {
  /* display: -webkit-inline-box; */
  /* margin-top: 2%; */
  /* margin-right: 4%; */
  display: flex;
  align-items: center;
  justify-content: end;
}

.icon-stateCat.switch-smcat.media-body {
  padding: 0px 3%;
}

.assetAdd .bg-success,
.assetAdd .bg-danger {
  height: 29px;
}

.assetAdd .switch-state:before {
  height: 21px;
}

.assetAdd .icofont-table:before {
  content: "\eec8";
  font-size: 17px;
}

.customDBcss {
  background-color: transparent !important;
  border: 0px !important;
  color: #212529;
}

.orbtn {
  width: 100% !important;
  background-color: transparent !important;
  border: 0px !important;
}

.downloadLink {
  text-align: center;
  color: #2d85c3 !important;
  cursor: pointer;
}

.importCol {
  background-color: #000 !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: -5%;
  width: 105%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.overlay.active {
  opacity: 1;
  pointer-events: auto;
}

.carddd.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.react-datepicker-wrapper {
  display: block !important;
}

.newclass {
  color: white;
}

.custom-fields-cell {
  white-space: pre-line;
}

.removecss {
  background-color: transparent !important;
  margin: 0px !important;
  padding: 0px !important;
  margin-top: -8% !important;
  min-height: 80vh !important;
}

.dropdown-basic .separated-btn {
  margin-left: -8px !important;
}

.dropdown-basic .separated-btn .btn {
  padding: 13px !important;
}

.scrollView {
  overflow-y: scroll !important;
  height: 660px !important;
}

.project-list H1 {
  margin: 0px 10px !important;
}

.table tbody.trhover tr.duplicateRow {
  background-color: #d63c3c !important;
}

.duplicateRow:hover {
  cursor: pointer;
}

.link-style {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

ol.breadcrumb {
  display: none;
}

.container-fluid {
  margin-bottom: -1%;
}

hr {
  color: #7c7c7c;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  background: #ebebeb !important;
}

.page-body {
  background-color: #f3f3f3 !important;
  margin-left: 280px;
  margin-right: 0px;
  padding-left: 3%;
  padding-right: 4%;
}

.page-wrapper .page-body-wrapper {
  margin-top: 0px !important;
}

li.sidebar-list.active.list-group-item,
li.sidebar-list.active.list-group-item {
  background: #fff !important;
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 0% 7% !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-list
  .svg-content {
  display: none !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .simplebar-wrapper
  .simplebar-mask
  .simplebar-content-wrapper
  .simplebar-content
  > li
  .sidebar-submenu
  li
  a.active {
  color: #111213;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .simplebar-wrapper
  .simplebar-mask
  .simplebar-content-wrapper
  .simplebar-content
  > li.sidebar-list:hover
  > a:hover {
  background-color: #fff !important;
}

.open_tasks_status {
  background: #8bc34a;
  text-align: center;
  color: #fff;
}

.completed_tasks_status {
  background: #8bc34a;
  text-align: center;
  color: #fff;
}

.hold_tasks_status {
  background: #f7be43;
  text-align: center;
  color: #fff;
}

a.sidebar-link.sidebar-title.active {
  background: #fff !important;
}

.width12 {
  width: auto !important;
}

/*
.css-13cymwt-control {
  background-color: #edf2f7 !important;
  border-color: #d9e7f4 !important;
}
select.form-select {
  background-color: #edf2f7 !important;
  border-color: #d9e7f4 !important;
  padding: 10px !important;
}*/
select.form-select {
  padding: 7px !important;
  height: 32px;
  color: #6c757d !important;
}

h3.page-title {
  font-size: 32px !important;
  text-transform: uppercase !important;
  font-weight: 800 !important;
  color: #203450;
}

.footer-copyright.text-center.col-md-12 {
  background-color: #dbdbdb;
  padding: 1%;
  text-align: left !important;
}

.bordorBottom {
  border-bottom: 1px solid #b6b6b6;
}

.row.text-right.card-footer {
  text-align: right;
}

.rc-pagination-item-active {
  font-weight: 500;
  background: #1890ff !important;
  border-color: #1890ff;
  color: #fff;
}

.footer-copyright p {
  color: #222;
  font-weight: 500;
}

.footer {
  background-color: #dbdbdb !important;
}

tr:nth-child(even) {
  background-color: #edf2f7;
}

.table {
  border: 1px solid #ebebeb !important;
}

.custom-card.pd5.card {
  padding: 2% 2% !important;
  margin-top: 0% !important;
}

.headingOfPlan {
  margin-top: 1% !important;
}

.custom-card .card-header {
  padding: 10px !important;
}

.pd30 {
  padding: 30px !important;
}

/*.profile-greeting .greeting-user h1, .profile-greeting .greeting-user .h1 {
  color: #fff !important;
}*/
select.form-select {
  height: 39px;
  color: #6c757d !important;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: 0 min-content;
  margin: 0px;
  padding-bottom: 2px;
  padding-top: 2px;
  box-sizing: border-box;
  border-color: #c5c5c5;
}

h1,
.h1 {
  color: #000;
}

form .form-control {
  font-size: 12px !important;
}

.margin0.row {
  margin: 3px !important;
}

.text-end.col-md-4.top6 {
  margin-top: 4px;
}

.project-list .row {
  margin: 1% !important;
}

.container-fluid H1 {
  color: #000;
}

.login-main1 button.btn-block.btn.btn-primary {
  color: #ffffff !important;
}

.custom-card.pd-1.card {
  padding: 1% !important;
}

table thead {
  position: sticky !important;
  top: 0;
  background-color: #fff;
}
