/**=====================
   72. Customizer  CSS Start
==========================**/
.customizer-links {
  position: fixed;
  right: 00px;
  top: 40%;
  background-color: $white;
  border-radius: 8px;
  overflow: hidden;
  z-index: 3;
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  transition: all 0.3s ease;
  &.open {
    right: 330px;
    border-radius: 8px 0 0 8px;
    transition: all 0.3s ease;
  }
  > .nav{
    padding: 10px;
    border-radius: 8px;
  }
  .nav-link {
    border-radius: 0;
    padding: 0;
    &.active{
      i{
        color: $primary-color;
      }
    }
    &:hover{
      height: 100%;
      width: 100%;
      background-color: rgba($primary-color,0.1);
      border-radius: 4px;
      i{
        color: $primary-color;
      }
    }
    i{
      font-size: 17px;
      color: gray;
    }
    .settings {
      img {
        display: inline-block;
        cursor: pointer;
        margin: 0;
      }
      i {
        padding: 8px 10px;
        border-radius: 5px;
        display: inline-block;
        cursor: pointer;
        margin: 0;
      }
      svg {
        fill: var(--theme-default);
        width: 25px;
        height: 25px;
      }
    }
    &.active {
      background-color: rgba($primary-color, 0.1);
    }
    & + .nav-link {
      border-top: 1px solid rgba($primary-color, 0.1);
    }
  }
}
.customizer-contain {
  direction: ltr;
  position: fixed;
  height: calc(100vh);
  top: 90px;
  width: 335px;
  right: -335px;
  background-color: $white;
  z-index: 99;
  transition: all 0.3s ease;
  &.open {
    right: 0px;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
  }
  li {
    position: relative;
    &.active {
      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        left: 0;
        top: 0;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark-color;
        box-shadow: 0 0 11px 5px rgba(226, 226, 226, 0.5);
        border-radius: 10px;
      }
    }
  }
  h6 {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #212529;
  }
  .customizer-header {
    padding: 15px 25px;
    border-bottom: 1px solid $light-color;
    .icon-close {
      position: absolute;
      font-size: 18px;
      cursor: pointer;
      font-weight: 900;
      right: 30px;
      color: var(--theme-default);
    }
    h5 {
      font-weight: 700;
      text-transform: uppercase;
      color: #313131;
    }
    p {
      color: #313131;
    }
  }
  .customizer-body {
    padding: 15px 25px;
    max-height: calc(100vh - 170px);
    overflow-y: scroll;
    .unlimited-color-layout{
      display: flex;
      align-items: center;
      flex-direction: column;
      input{
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        height: 35px;
        margin-right: 5px;
        width: 35px;
        padding: 2px;
      }
    }
    .dashboard-type {
      .dashboard-box {
        border: 1px solid $light-semi-gray;
        text-align: center;
        .title-wrraper {
          padding: 10px;
          h3 {
            font-size: 18px;
            margin-bottom: 0;
          }
        }
      }
      .carousel-control-prev,
      .carousel-control-next {
        background-color: gray;
      }
    }
    .layout-grid {
      &.customizer-mix {
        .color-layout {
          height: 70px;
          width: 80px;
          border: 1px solid #f6f7fb;
          border-radius: 5px;
          display: inline-block;
          margin-bottom: 7px;
          margin-right: 3px;
        }
      }
    }
    .tab-pane {
      > h6 {
        position: relative;
        margin-top: 25px;
        padding-top: 25px;
        margin-bottom: 20px;
        border-top: 1px solid #f3f3f3;
        font-size: 15px;
        &:before {
          content: "";
          position: absolute;
          height: 3px;
          width: 14px;
          border-bottom: 2px dotted $dark-color;
          bottom: -4px;
        }
        &:first-child {
          border-top: 0;
          padding-top: 0;
          margin-top: 0;
        }
      }
    }
    .nac-pills {
      border: 1px solid #f1f1f1;
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .nav-item {
        width: 33.33%;
        text-align: center;
        .nav-link {
          border-radius: 5px;
          &.active {
            background-color: var(--theme-default);
            color: $white;
          }
        }
      }
      .nav-link {
        padding: 0.2rem 1rem;
      }
    }
    li {
      cursor: pointer;
      opacity: 0.9;
      &.active {
        opacity: 1;
      }
    }
    .fade {
      &:not(.show) {
        display: none;
      }
    }
    .color-layout {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      display: inline-block;
      margin-bottom: 7px;
      margin-right: 3px;
      border: 1px solid #b8b8b8;
      padding: 5px;
      > div {
        background-color: #4d8aff;
        height: 100%;
        width: 100%;
        border-radius: 100%;
      }
      .body {
        .contain {
          position: absolute;
          height: auto;
          width: 100%;
          li {
            height: 1px;
            width: 65%;
            background-color: #4d8aff;
            border-radius: 5px;
            padding: 0;
            margin-left: 10px;
            margin-bottom: 4px;
            &:before {
              content: "";
              position: absolute;
              height: 1px;
              width: 3px;
              background-color: #4d8aff;
              left: -6px;
              border-radius: 5px;
            }
          }
        }
      }
    }
    > li {
      .body {
        .body {
          ul {
            background-color: #f6f7fb;
          }
        }
      }
    }
    .dark {
      > li {
        background-color: #2f3c4e;
        .body {
          ul {
            background-color: #2f3c4e;
          }
        }
      }
    }
    .main-layout {
      > li {
        height: 70px;
        width: 86px;
        border: 1px solid $light-color;
        border-radius: 10px;
        display: inline-block;
        & + li {
          margin-left: 7px;
        }
      }
      .box-layout {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 16px;
        .body {
          .badge {
            left: -14px;
          }
        }
      }
    }
    .sidebar-type,
    .sidebar-setting {
      > li {
        height: 70px;
        width: 86px;
        border: 1px solid $light-color;
        border-radius: 5px;
        display: inline-block;
        & + li {
          margin-left: 7px;
        }
      }
    }
    .sidebar-type {
      li {
        &:first-child {
          .body {
            ul {
              li {
                &.bg-dark,
                &.sidebar {
                  width: 100%;
                  height: 10px;
                  display: block;
                }
                &.bg-light,
                &.body {
                  height: calc(100% - 10px);
                }
              }
            }
          }
        }
      }
    }
    .sidebar-bg-settings {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        height: 100px;
        width: 100%;
        border: 1px solid $light-color;
        border-radius: 5px;
        display: inline-block;
        margin-right: 9px;
        box-shadow: 0 0 11px -2px rgba(128, 128, 128, 0.4);
      }
    }
  }
  .customizer-footer {
    position: absolute;
    bottom: 0;
    padding: 25px;
    width: 100%;
    border-top: 1px solid $light-color;
    background-color: $white;
  }
}
.layout-grid:not(.customizer-color) {
  li {
    padding: 5px;
    > .header {
      height: 9px;
      border-radius: 3px;
      position: relative;
      ul {
        position: absolute;
        top: -8px;
        left: 5px;
        > li {
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 5px;
          top: calc(50% - 3px);
          background-color: var(--theme-default);
          margin-right: 1px;
          padding: 0;
          &:nth-child(2) {
            background-color: var(--theme-secondary);
          }
          &:nth-child(3) {
            background-color: $success-color;
          }
        }
      }
    }
    > .body {
      background-color: $light-color;
      height: calc(100% - 16px);
      border-radius: 3px;
      position: relative;
      margin-top: 5px;
      text-align: center;
      line-height: 3.1;
      ul {
        height: 100%;
        background-color: $white;
        .sidebar {
          width: 18px;
          height: 100%;
          border-radius: 3px;
          display: inline-block;
          margin-right: 3px;
          padding: 0;
          &.ms-1{
            margin-right: 0 !important;
            margin-left: 0.25rem !important;
          }
          &.compact {
            width: 12px;
            & ~ .body {
              width: calc(100% - 19px);
            }
          }
          &.compact-icon {
            width: 7px;
            & ~ .body {
              width: calc(100% - 14px);
            }
          }
        }
        .body {
          width: calc(100% - 25px);
          padding: 0;
          margin: 0;
          height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          .badge {
            position: absolute;
          }
        }
      }
    }
  }
}
/**=====================
    72.Customizer CSS Ends
==========================**/