@import "~bootstrap-scss/bootstrap.scss";
@import "vendors/chartist/chartist";
@import "vendors/whether-icon.scss";
@import "vendors/feather-icon.scss";
@import "vendors/svg-icon/svg-icon";
@import "vendors/font-awesome.scss";
@import "vendors/flag-icon.scss";
@import "vendors/icoicon/icons";
@import "vendors/animate/animate";
@import "vendors/sticky/sticky";
@import "vendors/todo/todo";
@import "vendors/icofont.scss";
@import "vendors/themify.scss";
@import "themes/authLogin.scss";

// Datepicker
@import "~react-datepicker/dist/react-datepicker.css";
@import "~react-big-calendar/lib/css/react-big-calendar.css";

//  calendar
@import "~@fullcalendar/daygrid/main.css";
@import "~react-big-calendar/lib/css/react-big-calendar.css";

// Dropzone
@import "~react-dropzone-uploader/dist/styles.css";

//leaflet map
@import "leaflet/dist/leaflet.css";

// ReactToastify
@import "~react-toastify/dist/ReactToastify.css";

// Typeahead
@import "~react-bootstrap-typeahead/css/Typeahead.css";

// ScrollBar
@import "~react-perfect-scrollbar/dist/css/styles.css";

//  ImageCrop
@import "~react-image-crop/dist/ReactCrop.css";

// Drag&Drop
@import "~react-dragula/dist/dragula.css";

// Timeline
@import "~react-vertical-timeline-component/style.min.css";

// Mde EDitor
@import "~easymde/dist/easymde.min.css";

@import "style.scss";

@import "responsive.scss";

//gallery
@import "photoswipe/dist/photoswipe.css";

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

// CUSTOME CARDS UI CUSTOMIZATION
.custom-card {
  border-radius: 16px !important;
  margin-bottom: 0px !important;
  margin-top: 35px !important;
  padding: 0 !important;
  box-shadow: 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px #0007161f !important;

  .input-group-text {
    .search-btn {
      color: #ffffff !important;
      background-color: #138ff2 !important;
      border-radius: 12px;
      padding: 10px 22px !important;
    }
  }

  .nav-item {
    margin-right: 10px;
  }

  .nav-tabs .nav-link.active {
    border-radius: 12px;
    background: #ce44f0 !important;
    color: white !important;
    box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06) !important;
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  .nav-tabs .nav-link {
    &:not(.active) {
      border-radius: 12px;
      background: #d0cdcd;
      color: white;
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }

  .export-button {
    button {
      &.btn {
        &.btn-primary {
          color: white !important;
          background-color: #138ff2 !important;
        }
      }
    }
  }

  .task-button {
    button {
      &.btn {
        &.btn-primary {
          border-radius: 12px !important;
          border: 1px solid #138ff2 !important;
          background-color: unset !important;
          color: #138ff2 !important;
          padding: 12px 18px 12px 18px !important;
        }
      }
    }
  }
}

// CATEGORY LIST UI
.category-list {
  .overlay-dark {
    background-color: rgba(black, 0.4);
  }

  .card-img {
    height: 224px;
    object-fit: cover;
  }

  // .ribbon {
  //     position: relative;
  //     top: 0;
  //     right: 0;
  //     border-top-right-radius: 6px;
  //     border-bottom-left-radius: 10px;
  //     font-size: 12px;

  //     &:before {
  //         content: none;
  //     }
  // }

  .static-widget {
    padding: 30px !important;

    H4,
    H6 {
      font-weight: normal !important;
      color: #ffffff !important;
      font-family: "Varela Round" !important;
    }
  }
}

// CUSTOM TABLE HEAD
.custom-table-head {
  th {
    background: #ffffff !important;
    color: black !important;

    &:first-child {
      border-radius: 0px 0px 0px 0px !important;
    }

    &:last-child {
      border-radius: 0px 0px 0px 0px !important;
    }
  }
}

// TASK FILTER
.task-filter {
  .form-select {
    padding: 15px !important;
  }

  .form-control {
    padding: 15px !important;
  }
}
