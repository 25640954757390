/**=====================
    71. Responsive CSS Start
==========================**/
@media (max-width: 1800px) {
  .table-responsive{
    overflow-x: scroll;
  }
}
@media screen and (max-width: 1660px) {
  // blog
  .blog-list {
    .blog-details {
      p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .photos {
    ul {
      li {
        width: 22%;
      }
    }
  }
  .bar-chart {
    canvas {
      width: 100% !important;
    }
  }
  .chat-box {
    .chat-history {
      .call-icons {
        margin-bottom: 25px;
      }
      .total-time {
        h2 {
          color: $dark-gray;
          margin-bottom: 18px;
        }
      }
      .call-content {
        min-height: 545px;
        .btn {
          width: auto;
          margin: 0 auto;
        }
        > div {
          z-index: 7;
          width: 100%;
          padding: 30px;
        }
      }
      .receiver-img {
        margin-top: 25px;
      }
    }
  }
  .chat-left-aside {
    .people-list {
      height: 520px;
    }
  }
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 440px;
        }
      }
    }
    .people-list {
      ul {
        max-height: 450px;
        overflow: auto;
      }
    }
  }
  .call-chat-body {
    .chat-box {
      .people-list {
        ul {
          max-height: 550px;
          overflow: auto;
        }
      }
      overflow: hidden;
      height: 625px;
    }
  }
  .caller-img {
    img {
      opacity: 0.7;
    }
  }
  // email wrap
  .email-wrap {
    .email-right-aside {
      .email-profile {
        .inbox {
          p {
            width: calc(100% - 300px);
            max-width: 500px;
          }
        }
      }
    }
  }
  // chat page //
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-header {
          .chat-menu-icons {
            li {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .default-dash,
  .ecommerce-dash,
  .crypto-dash {
    .dash-xlorder-1 {
      order: 1;
    }
  }
}
@media screen and (max-width: 1550px) and (min-width: 1400px) {
  .wishlist-main{
    > div{
      &.xl-25 {
        max-width: 25%;
        flex: 0 0 25%;
      }
    }
  }
}
@media screen and (max-width: 1550px) {
  .social-app-profile{
    .pepole-knows{
      .add-friend{
        .btn{
          font-size: 10px;
          padding: 2px 2px 0;
        }
      }
    }
  }
  .search-page{
    .blog-box{
      .blog-details{
        .blog-social{
          li{
            &:nth-child(n+2){
              padding-left: 15px;
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
  .blog-box{
    .blog-details{
      .blog-social{
        li{
          &:nth-child(n+2) {    
            padding-left: 5px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .email-wrap {
    .email-top {
      .email-right {
        display: none;
      }
    }
    .email-content {
      .email-top {
        .user-emailid {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .cal-date-widget {
    .datepicker {
      width: unset;
    }
  }
}
@media screen and (max-width: 1920px) and (min-width: 1661px) {
  .default-dash,
  .ecommerce-dash,
  .crypto-dash {
    .dash-31 {
      max-width: 31%;
      flex: 0 0 31%;
    }
    .dash-35 {
      max-width: 38%;
      flex: 0 0 38%;
    }
    .dash-39 {
      max-width: 39%;
      flex: 0 0 39%;
    }
    .dash-29 {
      max-width: 29%;
      flex: 0 0 29%;
    }
    .dash-32 {
      max-width: 32%;
      flex: 0 0 32%;
    }
  }
  .chat-box{
    .chat-history{
      .call-content{
        min-height: 550px;
      }
    }
  }  
}
@media screen and (max-width: 1660px) and (min-width: 1200px) {
  .whishlist-main {
    .prooduct-details-box .btn {
      top: 0;
      bottom: unset;
      right: unset;
      left: 0;
    }
    .col-xl-2 {
      width: 25%;
    }
  }
  .dash-xl-50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .dash-xl-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .dash-xl-33 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
  .xl-none {
    display: none;
  }
  .xl-33 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
  .xl-60 {
    max-width: 60%;
    flex: 0 0 60%;
  }
  .xl-70 {
    max-width: 70%;
    flex: 0 0 70%;
  }
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .xl-30 {
    max-width: 30%;
    flex: 0 0 30%;
  }
  .xl-40 {
    max-width: 40%;
    flex: 0 0 40%;
  }
  .xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
  .product-page-main {
    .owl-item {
      .item {
        border: 1px solid $light-color;
        border-radius: 5px;
      }
    }
    .proorder-xl-3 {
      order: 3;
    }
  }
  .owl-carousel {
    .owl-item {
      img {
        max-width: 420px;
        margin: 0 auto;
      }
    }
  }
}
@media screen and (max-width: 1365px) and (min-width: 1200px) {
  .default-dash,
  .ecommerce-dash,
  .crypto-dash {
    .dash-lg-50 {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .dash-lg-100 {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  .lg-50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .lg-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media screen and (max-width: 1365px) {
  .trading-activities{
    table{
      tbody{
        tr{
          td{
            &:nth-child(3){
              min-width: 96px;
            }
          }
        }
      }
    }
  }
  .default-dash,
  .ecommerce-dash,
  .crypto-dash {
    .dash-lgorder-1 {
      order: 1;
    }
  }
}
@media screen and (max-width: 1100px) and (min-width: 992px) {
  .dash-xl-50 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .dash-lg-50 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .profile-greeting {
    height: 180px;
    .greeting-user {
      h1 {
        font-size: 24px;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
      }
    }
    .cartoon-img {
      img {
        margin-right: -110px;
      }
    }
  }
  .premium-access {
    height: 212px;
  }
}
@media (max-width: 1280px) {
  // blog
  .blog-list .blog-details {
    hr {
      display: none;
    }
    p {
      display: none;
    }
  }
  .img-gallery-set1,
  .img-gallery-set5 {
    display: none;
  }
  .box-layout.page-wrapper.horizontal-wrapper {
    .page-header {
      padding-left: unset;
      max-width: unset;
      left: unset;
    }
    .page-body-wrapper {
      width: unset;
    }
  }
  // emaill application
  .email-wrap {
    .actions {
      li {
        &:nth-child(n + 2) {
          margin-left: 10px;
        }
        .btn {
          padding: 7px 20px;
          text-transform: capitalize;
        }
      }
    }
  }
  .user-profile {
    .photos {
      ul {
        li {
          width: 20%;
        }
      }
    }
    .social-tab {
      ul {
        li {
          &:nth-child(n + 2) {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 1470px) {
  .user-card {
    .card-profile {
      img {
        height: 90px;
      }
    }
  }
  .theme-form {
    select {
      padding: 8px 14px;
    }
  }
  //edit-profile //
  .edit-profile {
    .profile-title {
      .media {
        display: block;
        .media-body {
          margin-left: unset;
          margin-top: 10px;
        }
      }
    }
  }
  .product-grid {
    .col-xl-2 {
      .product-img {
        .ribbon {
          padding: 0 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 1366px) and (min-width: 1200px) {
  .bookmark-wrap{
    .md-sidebar{
      .left-bookmark{
        .media{
          display: block;
          img.media{
            margin: 0 auto 10px !important;
          }
          .media-body{
            text-align: center;
          }
        }
      }
    }
  }
  .flot-chart-container-small {
    height: 227px;
  }
  // email-app
  .email-wrap {
    .email-left-aside {
      .email-app-sidebar {
        .media {
          display: block;
          .media-size-email {
            width: 100%;
            text-align: center;
            img {
              margin: 0 auto 10px !important;
            }
            .me-3 {
              margin-right: 0 !important;
            }
          }
          .media-body {
            text-align: center;
          }
        }
      }
    }
    .email-right-aside {
      .email-body {
        .attachment {
          ul {
            li {
              img {
                width: 73px;
                height: 73px;
              }
            }
          }
        }
        .email-top {
          line-height: 50px;
          h5 {
            padding: 13px 0;
          }
        }
        .inbox {
          .media-body {
            text-align: center;
            h6 {
              font-size: 14px;
            }
          }
        }
        .email-compose {
          .cke_contents.cke_reset {
            max-height: 144px;
            width: 99%;
          }
          .compose-border {
            margin: 0;
          }
        }
      }
    }
    .media-body {
      p {
        font-size: 13px;
      }
    }
  }
  .call-chat-sidebar {
    max-width: 262px;
  }
  .product-box {
    .product-details {
      padding: 15px;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .flot-chart-container {
    height: 130px;
  }
  .user-card {
    .custom-card {
      .card-profile {
        img {
          height: 85px;
        }
      }
    }
  }
  .custom-card {
    .card-profile {
      img {
        height: 100px;
      }
    }
    .profile-details {
      h4 {
        font-size: 18px;
      }
      h6 {
        margin-bottom: 0;
      }
    }
    .card-footer {
      > div {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
  .crm-activity {
    height: 291px;
    overflow-y: auto;
    .media {
      .media-body {
        .dates {
          display: flex;
        }
      }
    }
  }
  // chat
  .chat-body {
    padding: 10px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        .btn {
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;
      }
      .receiver-img {
        margin-top: 30px;
      }
      .call-icons {
        margin-top: 20px;
        margin-bottom: 20px;
        ul {
          li {
            width: 50px;
            height: 50px;
            padding: 7px;
          }
        }
      }
    }
    .user-image {
      width: 30px;
      height: 30px;
      margin-top: 3px;
      margin-right: 3px;
    }
    .about {
      margin-top: 0;
    }
    .border-tab.nav-tabs {
      .nav-item {
        a {
          padding: 13px 4px !important;
          color: $semi-dark !important;
          letter-spacing: 0;
          font-size: 12px !important;
          line-height: 6;
        }
      }
    }
    .chat-menu {
      .people-list {
        ul.list {
          max-height: 376px;
          overflow-y: auto;
        }
      }
      .user-profile {
        .image {
          .icon-wrapper {
            width: 30px;
            height: 30px;
            font-size: 13px;
          }
        }
      }
    }
  }
  .chat-left-aside {
    .people-list {
      height: 420px;
      ul {
        max-height: 376px;
        overflow-y: auto;
      }
    }
    .status-circle {
      top: 4px;
      left: 36px;
    }
  }
  //blog scss
  .blog-box {
    .blog-details-main {
      .blog-bottom-details {
        margin: 20px 10px;
      }
      .blog-social {
        li {
          padding: 0px 5px;
        }
      }
    }
  }
  .blog-box.blog-shadow {
    .blog-details {
      padding: 15px;
    }
  }
  .comment-box {
    .media {
      img {
        margin-right: 25px;
      }
    }
    ul {
      ul {
        margin-left: 50px;
      }
    }
  }
  .browser-widget {
    .media-img {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 15px;
    }
    img {
      height: 50px;
    }
  }
  // alert page
  .alert-dismissible {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 181px;
    }
  }
}
@media only screen and (max-width: 1366px) {
  .bookmark-wrap {
    .task-sidebar {
      height: auto;
    }
  }
  // product page
  .span.ace_indent-guide {
    display: none;
  }
  .grid-options {
    ul {
      li {
        &:last-child {
          display: none;
        }
      }
    }
  }
  .product-price {
    font-size: 14px;
  }
  .product-box {
    .product-img {
      .product-hover {
        ul {
          li {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
    .product-details {
      h4 {
        font-size: 18px;
      }
    }
  }
  .chat-box {
    .chat-history {
      .call-content {
        min-height: 510px;
      }
    }
  }
  .chat-left-aside {
    .people-list {
      height: 490px;
      ul {
        max-height: 434px;
        overflow-y: auto;
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 25%;
        }
      }
    }
  }
  // alert page
  .alert-dismissible {
    strong {
      & ~ p {
        display: inline;
      }
    }
  }
  // chat page
  .chat-right-aside {
    overflow: hidden;
    .chat {
      .chat-message {
        .smiley-box {
          width: 45px;
        }
      }
    }
  }
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 410px;
          .chat-user-img {
            margin-top: -30px;
          }
          .message {
            width: 100%;
            padding: 15px;
          }
        }
        .chat-header {
          .chat-menu-icons {
            li {
              a {
                i {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
    .chat-history {
      .receiver-img {
        img {
          height: 150px;
        }
      }
    }
  }
  .social-app-profile {
    .custom-card {
      .card-footer {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
  .call-chat-body {
    .chat-box {
      overflow: hidden;
      height: 600px;
    }
  }
  .equal-height-xl {
    height: 517px;
  }
  .authentication-main {
    .auth-innerright {
      background-size: cover;
    }
  }
  .grp-btns {
    display: inline-block;
  }
  //ecommerce dashboard
  .ecommerce-widget {
    .total-num {
      font-size: 23px;
      letter-spacing: 0px;
    }
  }
  .user-card {
    .custom-card {
      .card-footer {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
  //default dashboard
  .custom-card {
    padding: 15px;
    .card-social {
      li {
        &:nth-child(n + 2) {
          margin-left: 6px;
        }
        a {
          padding: 10px;
          width: 30px;
          height: 30px;
          font-size: 13px;
        }
      }
    }
  }
  .speed-chart {
    .chart-container {
      height: 250px;
      margin-top: -40px;
      margin-bottom: 100px;
    }
  }
  .candidcahrt {
    height: 360px;
  }
  //chart widget
  .status-details {
    h3 {
      font-size: 18px;
    }
  }
  .chart-widget-top {
    .total-value {
      font-size: 20px;
    }
  }
  .status-widget {
    .card-body {
      padding: 10px;
    }
  }
  //general widget
  .calender-widget{
    .cal-desc{
      p{
        font-size: 14px;
      }
    }
  }
  .user-status {
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: unset;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 190px;
            }
          }
        }
      }
    }
  }
  .browser-widget {
    .media {
      flex-wrap: wrap;
      .media-body {
        h3 {
          font-size: 20px;
        }
        p {
          margin-bottom: 2px;
        }
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .cal-date-widget .datepicker {
    margin: 0 auto;
    width: unset;
  }
  .company-card {
    padding: 20px;
  }
  //chat
  .chat-body {
    padding: 10px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        .btn {
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;
      }
      .receiver-img {
        margin-top: 30px;
      }
      .call-icons {
        margin-top: 20px;
        margin-bottom: 20px;
        ul {
          li {
            width: 50px;
            height: 50px;
            padding: 7px;
          }
        }
      }
    }
    .user-image {
      width: 45px;
      height: 45px;
    }
    .about {
      margin-top: 0;
    }
    .border-tab.nav-tabs {
      .nav-item {
        a {
          padding: 13px 4px !important;
          color: $semi-dark !important;
          letter-spacing: 0;
          font-size: 12px !important;
          line-height: 3;
        }
      }
    }
    .chat-menu {
      .people-list {
        ul.list {
          max-height: 320px;
          overflow-y: auto;
        }
      }
      .user-profile {
        margin-top: 0;
        .image {
          .icon-wrapper {
            width: 30px;
            height: 30px;
            font-size: 13px;
          }
        }
        .user-content {
          p {
            font-size: 14px;
            margin-bottom: 10px;
          }
          h5 {
            margin: 20px 0;
          }
          hr {
            margin: 20px 0;
          }
        }
        .follow {
          .follow-num {
            font-size: 17px;
          }
        }
      }
    }
  }
  .chat-left-aside {
    .people-list {
      height: 490px;
      ul {
        max-height: 434px;
        overflow-y: auto;
      }
    }
  }
  .status-circle {
    top: 4px;
    left: 37px;
  }
  // mega-menu
  .mega-menu {
    .onhover-show-div {
      left: 164px;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    .dropdown-basic {
      .dropdown {
        .dropdown-content {
          left: -21px;
        }
      }
    }
  }
  // forget password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          padding: 6px 22px;
        }
      }
    }
  }
  // blog
  .learning-box {
    .details-main {
      padding: 20px;
      h6 {
        font-size: 18px;
      }
    }
  }
}
@media only screen and (max-width: 1366px) and (min-width: 1200px) {
  .page-wrapper {
    &.compact-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            flex: 0 0 64%;
            max-width: 64%;
          }
        }
      }
    }
    &.horizontal-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            flex: 0 0 54%;
            max-width: 54%;
          }
        }
      }
    }
  }
  .product-box {
    .product-details {
      padding: 15px;
    }
  }
}
@media screen and (max-width: 1580px) and (min-width: 768px) {
  //ecommerce page
  .product-filter {
    &.new-products {
      .owl-theme {
        .owl-item {
          .item {
            .product-box {
              .media {
                display: block;
                .product-img {
                  width: 100%;
                }
              }
              .product-details {
                padding: 15px 0;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1214px) {
  .product-thumbnail,
  .product-main {
    flex: 0 0 auto;
    width: 100% !important;
  }
  .product-thumbnail {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 1199px) {
  // calender page
  .calendar-wrap {
    .fc-day-grid-event {
      width: 87%;
    }
  }
  .user-profile{
    .photos{
      ul{
        li{
          &:nth-child(3n){
            margin-right: 10px;
          }
        }
      }
    }
  }
  .chat-box{
    .chat-history{
      .call-content{
        padding-right: 12px !important;
      }
    }
  }  
  .checkout{
    .order-place{
      margin-bottom: 18px;
    }
  }
  .file-spacing{
    .md-sidebar.job-sidebar{
      .md-sidebar-aside.job-left-aside{
        top: 28% !important;
      }
    } 
    .job-sidebar{
      .job-left-aside{
        padding: 0;
        .file-sidebar{
          .card{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  // Email app
  .email-wrap {
    .email-right-aside {
      .email-body {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        .b-r-light {
          border-right: none !important;
        }
      }
      .radius-left {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }
    }
    .email-aside-toggle {
      display: block;
    }
    .email-sidebar {
      position: relative;
      .email-aside-toggle {
        margin-bottom: 20px;
        width: fit-content;
        z-index: 0;
      }
      .email-left-aside {
        position: absolute;
        top: 100%;
        left: 0;
        opacity: 0;
        visibility: hidden;
        z-index: 2;
        width: 280px;
        transition: all 0.5s ease;
        &.open {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  .product-page-details {
    margin-top: 30px;
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 32%;
        }
      }
    }
  }
  .md-sidebar.job-sidebar {
    .md-sidebar-aside.job-left-aside {
      top: 27% !important;
      padding: 0;
      border-radius: 15px;
      .email-left-aside{
        .card{
          margin-bottom: 0;
        }
      }
    }
  }
  // product page //
  .grid-options {
    ul {
      li {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
  .product-page-main {
    .proorder-lg-1 {
      order: -1;
    }
  }
  .social-tab {
    padding: 20px;
    ul {
      li {
        a {
          padding: 8px 10px;
          font-size: 12px;
        }
      }
    }
    .input-group {
      .form-control {
        height: 40px;
      }
    }
  }
  .chat-box {
    .chat-right-aside {
      .caller-img-sec {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 1199.98px) {
  .jkanban-container {
    .kanban-container {
      .kanban-drag {
        > div {
          width: 400px;
          flex-wrap: wrap;
          > div {
            flex-wrap: wrap;
            .react-kanban-column {
              display: block !important;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
  //blog
  .blog-list .blog-details {
    p,
    hr {
      display: block;
    }
  }
  .blog-single {
    .comment-box {
      ul {
        .comment-social {
          margin-left: 0;
        }
      }
    }
  }
  .learning-block {
    > div {
      &:first-child {
        order: 2;
      }
    }
  }
  .knowledgebase-bg {
    height: 400px;
  }
  .knowledgebase-search {
    .form-inline {
      width: 400px;
      padding: 7px 45px;
      svg {
        top: 14px;
      }
    }
  }
  .btn-group {
    .btn {
      padding: 0.375rem 1.25rem;
    }
  }
  .link-section > div {
    &.active {
      > h6 {
        &:before {
          content: "\f107";
          transition: content 0.3s ease;
        }
      }
    }
    > h6 {
      position: relative;
      &:before {
        content: "\f105";
        font-family: fontAwesome;
        position: absolute;
        right: 10px;
        font-size: 18px;
        transition: content 0.3s ease;
      }
    }
  }
  .alert-theme {
    min-width: 420px;
  }
  .mega-menu-header {
    .header-wrapper {
      .vertical-mobile-sidebar {
        display: block;
        margin-right: 15px;
      }
    }
  }
  // sidebar css
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          position: unset;
          padding: 0;
          > ul {
            li {
              .submenu {
                li {
                  &:first-child {
                    padding-left: 15px !important;
                  }
                }
              }
            }
          }
        }
        .mobile-sidebar {
          .switch-sm {
            .switch {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
  // social app page
  .photos {
    ul {
      li {
        width: auto;
        &:nth-child(3n) {
          margin-right: 15px;
        }
      }
    }
  }
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          position: absolute;
          right: 45px;
          top: -22px;
          li {
            .social-icon {
              width: 40px;
              height: 40px;
              i {
                font-size: 18px;
              }
            }
            color: $white;
            font-size: 25px;
          }
        }
      }
    }
  }
  .lg-mt-col {
    margin-top: 30px;
  }
  .xl-none {
    display: none;
  }
  .user-status {
    table {
      tbody {
        tr {
          td {
            .d-inline-block {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .lg-mt {
    margin-top: 30px;
  }
  .product-filter.new-products{
    .slick-slider{
      .slick-slide{
        .item{
          .product-box{
            .product-img{
              width: 100%;
            }
            .product-details{
              padding: 16px 16px;
            }
          }
        }
      }
    }
  }     
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 32%;
        }
      }
    }
  }
  .debit-card {
    order: 1;
  }
  .typography {
    .row {
      div {
        & + div {
          margin-top: 30px;
        }
      }
    }
  }
  .listing {
    .card-body {
      .row {
        > div {
          & + div {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .flot-chart-container {
    height: 200px;
  }
  .error-wrapper {
    .maintenance-icons {
      li {
        i {
          color: $light-color;
          font-size: 40px;
        }
        &:nth-child(2) {
          i {
            font-size: 80px;
            margin-left: 90px;
          }
        }
        &:nth-child(3) {
          i {
            font-size: 120px;
            margin-top: -75px;
            margin-right: 110px;
          }
        }
      }
    }
  }
  .custom-card {
    .card-profile {
      .card-footer {
        > div {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
  .equal-height-lg {
    min-height: unset !important;
  }
  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 435px;
      overflow-x: scroll;
      left: 215px;
    }
    .mega-bg {
      display: none;
    }
    .lg-mt {
      margin-top: 25px;
    }
  }
  //error and maintenace
  .error-wrapper {
    .maintenance-heading {
      margin-top: 30px;
      .headline {
        font-size: 50px;
        margin-top: -15%;
        margin-bottom: 0px;
      }
      .cloud-second {
        margin-top: -25px;
      }
    }
  }
  // general widget
  .user-status {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 278px;
            }
          }
        }
      }
    }
  }
  .contact-form {
    padding: 10px;
    .theme-form {
      .form-icon {
        margin-top: -57px;
      }
    }
  }
  .browser-widget {
    padding: 0px;
    img {
      height: 50px;
    }
    .media {
      .media-body {
        h4 {
          font-size: 17px;
        }
      }
      .media-img {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
  //ecommerce dashboard
  .crm-overall {
    .overall-chart {
      height: 200px;
    }
  }
  //chat
  .chat-menu-icons {
    display: block;
    clear: both;
  }
  .chat-menu {
    right: 0;
    border-top: 1px solid $light-semi-gray;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 120px;
    position: absolute !important;
    z-index: 7;
    background-color: $white;
    transition: all linear 0.3s;
    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all linear 0.3s;
      padding-bottom: 25px;
    }
  }
  .chat-box {
    .chat-menu {
      .people-list {
        ul.list {
          max-height: 444px;
          overflow-y: auto;
        }
      }
    }
    .btn-lg {
      font-size: 12px;
    }
    .people-list {
      ul {
        height: auto;
      }
    }
    .chat-right-aside {
      .chat {
        .chat-header {
          height: auto;
          .chat-menu-icons li a i {
            font-size: 19px;
          }
        }
        .chat-message {
          margin: 0;
        }
      }
    }
    .chat-history {
      .total-time h2 {
        font-size: 25px;
      }
      .row {
        .col-sm-7 {
          padding-right: 0;
        }
      }
    }
    .border-tab.nav-tabs {
      width: 100%;
      .nav-item {
        margin-right: 5px;
      }
    }
    .chat-left-aside {
      .people-list {
        height: 493px;
      }
    }
    .toogle-bar {
      display: inline-block;
      margin-right: 0 !important;
    }
  }
  .chat-right-aside {
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
  }
  .call-chat-body {
    .chat-box {
      overflow: auto;
      height: auto;
    }
  }
  // icons
  .icon-lists {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 209px;
    }
  }
  // tour page
  .like-comment-lg-mb {
    margin-bottom: 12px;
  }
  // ribbons page
  .ribbon-space-bottom {
    top: 9px;
  }
  // user-profile page
  .profile-img-style {
    #aniimated-thumbnials-3 {
      text-align: center;
    }
  }
  // vertical page
  .vertical-menu-mt {
    margin-top: 80px !important;
    .xl-none {
      display: block;
    }
  }
  // product-page //
  .product-page-main {
    .product-page-details {
      h3 {
        font-size: 26px;
      }
    }
  }
  // md sidebar
  .md-sidebar {
    .md-sidebar-toggle {
      display: block;
    }
    .md-sidebar-aside {
      position: absolute;
      top: 36px;
      left: 10px;
      opacity: 0;
      visibility: hidden;
      z-index: 3;
      width: 280px;
      &.open {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  // job serach
  .job-sidebar {
    .job-left-aside {
      height: 500px;
      overflow: auto;
      background-color: $white;
      padding: 20px;
      border: 1px solid $light-gray;
    }
  }
}
@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .left-side-header{
    .input-group{
      span{
        padding-left: 10px;
      }
      input{
        padding: 10px 10px 10px 0;
      }
    }
  }
  .page-wrapper {
    &.compact-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            flex: 0 0 71.66667%;
            max-width: 71.66667%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991.98px) {
  // timeline page
  .vertical-timeline--one-column {
    .vertical-timeline-element-icon {
      width: 40px;
      height: 40px;
    }
    .vertical-timeline-element-content {
      margin-left: 60px;
    }
    &:before {
      left: 18px !important;
    }
  }
  .vertical-timeline-element-icon {
    svg {
      width: 20px;
      height: 20px;
      left: 57%;
      top: 55%;
    }
  }
  .carousal-page{
    .slick-dots{
      li{
        height: 28px;
      }
    }
  }
  .pricing-col{
    margin-bottom: -10px;
  }
  .pricing-block{
    .row{
      margin-bottom: -20px;
    }
  }
  .pricing-content{
    &.card-body{
      padding: 12px !important;
    }
  }
  .wishlist-main{
    margin-bottom: -20px;
  }
  .prooduct-details-box{
    margin-bottom: 20px;
  }
  .bookmark-tabcontent{
    .details-bookmark{
      &.list-bookmark{
        .bookmark-card.card{
          .title-bookmark{
            .hover-block{
              top: 58px;
            }
          }
        }
      }
    }
  }    
  .page-wrapper{
    .page-header{
      .header-wrapper{
        .nav-right{
          ul{
            li{
              h6.txt-dark{
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }   
  .status_toggle {
    img {
      width: 20px;
      height: 20px;
    }
  }
  .simplebar-mask {
    top: 80px;
  }
  .project-cards {
    .tab-content {
      margin-bottom: -20px;
    }
  }
  .edit-profile {
    .card-body {
      h6 {
        &.form-label {
          font-size: 16px;
        }
      }
    }
  }
  .project-box {
    margin-bottom: 20px;
  }
  .product-page-details {
    h3 {
      font-size: 22px;
    }
  }
  .product-page-main {
    padding: 20px;
  }
  .grid-options {
    ul {
      li {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
  .feature-products {
    form {
      .form-group {
        i {
          right: 20px;
        }
      }
    }
  }
  .card {
    .date-range-picker {
      .daterange-card {
        .sub-title {
          font-size: 16px;
        }
      }
    }
  }
  .product-box {
    .modal {
      .modal-header {
        .product-box {
          .product-details {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 20px;
    }
  }
  .search-form {
    .form-group {
      &:before {
        top: 33px;
      }
      &:after {
        top: 35px;
      }
    }
  }
  .mobile-title {
    &.d-none {
      padding: 11px 30px 10px;
    }
  }
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          .profile-dropdown {
            top: 50px;
            width: 160px;
          }
        }
      }
    }
  }
  .customizer-contain {
    top: 62px;
  }
  .mobile-clock-widget {
    #date {
      margin-top: 20px;
    }
  }
  .m-r-30 {
    margin-right: 20px;
  }
  .vertical-mobile-sidebar {
    right: 75px;
    top: 18px;
  }
  .gallery {
    margin-bottom: -20px;
  }
  .my-gallery {
    figure {
      margin-bottom: 20px;
    }
  }
  #aniimated-thumbnials {
    figure {
      &:nth-child(12),
      &:nth-child(11),
      &:nth-child(10),
      &:nth-child(9) {
        margin-bottom: 20px;
      }
    }
  }
  .photoswipe-pb-responsive {
    padding-bottom: 25px !important;
  }
  // main header css
  .page-wrapper {
    .section-space {
      padding: 60px 0;
    }
    .card {
      margin-bottom: 20px;
      .card-header,
      .card-body,
      .card-footer {
        padding: 20px;
        .tab-content {
          .m-t-30 {
            margin-top: 25px !important;
          }
          .m-b-30 {
            margin-bottom: 20px !important;
          }
        }
      }
    }
    .page-body-wrapper {
      .footer-fix {
        margin-left: 0px;
        padding-right: 15px;
        width: calc(100% - 0px) !important;
      }
    }
    &.compact-wrapper {
      .page-header {
        margin-left: 0 !important;
        width: calc(100% - 15px) !important;
        .header-wrapper {
          .maximize {
            display: none;
          }
          .header-logo-wrapper {
            display: flex;
          }
          .toggle-sidebar {
            position: unset;
            margin-right: 15px;
            border-right: 1px solid #ddd;
            padding-right: 15px;
            display: block;
            &:before {
              display: none;
            }
          }
          .toggle-nav {
            display: none;
          }
        }
      }
      .page-body-wrapper {
        div.sidebar-wrapper {
          top: 0;
          &.btn-close_icon {
            &:hover {
              transform: translate(-285px);
            }
          }
          .logo-wrapper {
            padding: 17px 30px;
            text-align: left;
            position: relative;
            .back-btn {
              display: inline-block;
              top: 50%;
              right: 10%;
              position: absolute;
              transform: translate(-50%, -50%);
              font-size: 20px;
            }
            .toggle-sidebar {
              display: none;
            }
          }
          .sidebar-main {
            .sidebar-links {
              height: calc(100vh - 350px);
            }
          }
          &.btn-close_icon {
            transform: translate(-285px);
            .sidebar-main {
              .sidebar-links {
                height: unset;
              }
            }
          }
        }
        .page-body {
          margin-top: 60px;
          padding: 0 15px;
        }
      }
    }
    .page-header {
      .header-wrapper {
        margin: 0;
        padding: 12px 30px;
        .nav-right {
          .notification {
            top: 10px;
          }
        }
        > .mobile-toggle {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .header-logo-wrapper {
          margin-right: 0;
        }
      }
      .header-logo-wrapper {
        .logo-wrapper {
          img {
            margin-top: 0;
          }
        }
      }
    }
  }
  .page-body-wrapper {
    .page-body,
    footer {
      margin-left: 0 !important;
    }
  }
  .default-according {
    .card {
      margin-bottom: 15px !important;
      .card-header {
        padding: 12px 20px;
      }
    }
  }
  // general widget page
  .crm-activity {
    > {
      li {
        + li {
          margin-top: 10px;
          padding-top: 10px;
        }
      }
    }
  }
  // add post page
  .add-post {
    .dropzone {
      margin-bottom: 20px;
    }
  }
  // avatar page
  .customers {
    &.avatar-group {
      margin-right: 0;
    }
  }
  //social app page css
  .socialprofile {
    .social-btngroup {
      margin: 20px 0;
    }
    .social-group {
      margin-top: 20px;
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-social {
          margin-bottom: 20px;
        }
      }
    }
    .media {
      margin-bottom: 20px;
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 20px;
    }
  }
  .social-chat {
    margin-top: 20px;
    .media-body {
      padding: 20px;
    }
    .your-msg,
    .other-msg {
      margin-bottom: 20px;
    }
  }
  .social-network {
    span {
      margin-bottom: 20px;
    }
  }
  .details-about {
    + .details-about {
      margin-top: 20px;
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 20px;
      }
    }
  }
  .new-users-social {
    margin-bottom: 20px;
  }
  // helper classes page start
  .helper-classes {
    padding: 20px;
  }
  // helper classes page ends
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        top: 24px;
      }
    }
  }
  .social-widget-card {
    .media {
      .social-font {
        width: 50px;
        height: 50px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .ecommerce-widget {
    .progress-showcase {
      margin-top: 20px;
    }
  }
  .crm-overall {
    margin: 0 -20px -20px;
  }
  .product-page-main {
    padding: 20px;
  }
  .grid-options {
    ul {
      li {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
  .feature-products {
    form {
      .form-group {
        i {
          right: 20px;
        }
      }
    }
  }
  .tabbed-card ul {
    padding: 18px 15px;
  }
  .tabbed-card {
    ul {
      top: -7px;
      right: 0;
      &.border-tab {
        &.nav-tabs {
          padding: 0;
          .nav-item {
            .nav-link {
              padding: 18px 15px;
            }
          }
        }
      }
    }
  }
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        top: 20px;
        right: 20px;
      }
    }
  }
  .custom-card {
    padding-bottom: 0;
    .card-footer {
      padding: 0 15px 15px 15px !important;
    }
    .card-header {
      border: none;
    }
    .dashboard-card {
      padding: 0;
    }
    .card-profile {
      img {
        height: 85px;
      }
    }
  }
  .display-1 {
    font-size: 4rem;
  }
  .display-2 {
    font-size: 3.5rem;
  }
  .display-3 {
    font-size: 2.5rem;
  }
  .display-4 {
    font-size: 1.5rem;
  }
  h1,
  .h1 {
    font-size: 28px;
  }
  h2,
  .h2 {
    font-size: 22px;
  }
  h3,
  .h3 {
    font-size: 18px;
  }
  h4,
  .h4 {
    font-size: 16px;
  }
  h5,
  .h5 {
    font-size: 14px;
  }
  h6,
  .h6 {
    font-size: 12px;
  }
  .card-absolute {
    .card-header {
      padding: 10px 15px !important;
    }
  }
  .comingsoon {
    .comingsoon-inner {
      .countdown {
        .time {
          width: 80px;
          height: 80px;
          font-size: 26px;
        }
      }
    }
  }
  .owl-theme {
    .owl-nav {
      &.disabled {
        & + .owl-dots {
          margin-bottom: -10px;
          margin-top: 15px;
        }
      }
    }
  }
  .color-shape {
    .card {
      .card-header {
        h5 {
          font-size: 17px;
        }
      }
    }
  }
  .card {
    .card-header {
      h5 {
        font-size: 18px;
      }
      .card-header-right {
        top: 20px;
        right: 15px;
      }
    }
  }
  //user profile
  .user-profile {
    .hovercard {
      .info {
        .user-designation {
          margin-bottom: 20px;
        }
      }
    }
  }
  // search page //
  .search-list {
    justify-content: center;
  }
  //blog
  .comment-box {
    .comment-social {
      li {
        &:nth-child(n + 2) {
          padding-left: 15px;
          margin-left: 15px;
        }
      }
    }
  }
  .top-radius-blog {
    width: 100%;
  }
  .sm-100-w {
    width: 100%;
    height: 100%;
  }
  //error and maintenace
  .error-wrapper {
    padding: 35px 0;
    .sub-content {
      font-size: 14px;
      line-height: 25px;
      margin-top: 0px;
    }
    .error-heading {
      margin-top: 30px;
      .headline {
        font-size: 180px;
        margin-top: 0;
      }
    }
    .maintenance-heading {
      margin-top: 30px;
    }
  }
  //search
  .search-page {
    .info-block {
      flex-wrap: wrap;
    }
    .tab-content {
      .pb-4 {
        padding-bottom: 20px !important;
      }
    }
  }
  //coming soon
  .comingsoon {
    .comingsoon-inner {
      img {
        width: 150px;
      }
    }
  }
  .contact-form {
    padding-top: 10px !important;
    .theme-form {
      padding: 20px;
      margin-top: 27px;
      .form-icon {
        margin-top: -47px;
      }
    }
  }
  //crm dashboard
  .user-status {
    &.product-chart {
      max-height: 518px;
      overflow: auto;
    }
  }
  // general widget
  .xl-none {
    display: block;
  }
  //blog
  .top-radius-blog {
    width: 100%;
  }
  .sm-100-w {
    width: 100%;
    height: 100%;
  }
  // tab material
  .border-tab.nav-tabs {
    margin-bottom: 20px;
  }
  .tab-content {
    ~ .nav-tabs {
      &.border-tab {
        margin-top: 20px;
      }
    }
  }
  // search page
  .search-page {
    .info-block {
      + .info-block {
        padding-top: 20px;
        margin-top: 20px;
      }
    }
    #video-links {
      .embed-responsive + .embed-responsive {
        margin-top: 20px;
      }
    }
  }
  .lg-mt {
    margin-top: 20px;
  }
  .gallery-with-description {
    margin-bottom: -20px;
    a {
      > div {
        margin-bottom: 20px;
      }
    }
  }
  // clipboard
  .clipboaard-container {
    p {
      margin-bottom: 8px;
    }
  }
  // summer-note
  .m-b-30 {
    margin-bottom: 20px !important;
  }
  // navs page
  .nav-md-mt {
    margin-top: 20px;
  }
  .navs-icon {
    padding: 20px;
    .main-section {
      padding-top: 20px;
    }
    .separator {
      margin: 20px 0;
    }
  }
  .nav-list {
    padding: 20px;
  }
  .navs-dropdown {
    button {
      margin-top: 20px;
    }
    .onhover-show-div {
      top: 68px;
    }
  }
  // steps page
  .steps-md-mt {
    margin-top: 20px;
  }
  // button group page
  .btn-group-wrapper {
    .m-b-30 {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
  // call-chat page
  .chat-box {
    .chat-left-aside {
      .people-list {
        height: 483px;
      }
    }
    .chat-right-aside {
      .chat {
        .chat-header {
          .chat-menu-icons {
            li {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .theme-form {
    &.form-inline {
      display: flex;
    }
  }
  .product-page-main {
    .product-page-details {
      h3 {
        font-size: 22px;
      }
    }
    .product-page-main {
      p {
        font-size: 12px;
      }
    }
    .product-social {
      li {
        a {
          font-size: 13px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .page-wrapper{
    .page-header{
      .header-wrapper{
        .nav-right {
          flex: 0 0 58%;
          max-width: 58%;
        }
      }
    }
  }
  .custom-profile{
    .card-footer{
      h6{
        font-size: 15px;
      }
    }
  }
}
@media only screen and (max-width: 810px) {
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          > ul {
            > li {
              .profile-media {
                .media-body {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .product-wrapper-grid {
    &.list-view {
      .ribbon {
        display: none;
      }
      .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}
@media (max-width: 780px) {
  .rbc-toolbar {
    display: block !important;
    margin: 0 auto;
    text-align: center;
    .rbc-btn-group {
      display: block;
      white-space: unset;
      &:first-child {
        margin-bottom: 10px;
      }
      &:last-child {
        margin-top: 10px;
        margin-bottom: 16px;
      }
    }
  }
}
@media only screen and (max-width: 767.98px) {
  .bordertab{
    &.card-body{
      padding-top: 0 !important;
    }
  }
  .alert-dismissible{
    .btn-close{
      top: 10px;
    }
  } 
  #external-events {
    position: relative;
    margin: 0 auto 14px;
  }
  .fc .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-center {
      padding: 10px 0;
    }
    .fc-toolbar-title {
      margin-bottom: 5px;
    }
  }
  //calendar
  .calendar-wrap {
    .fc {
      button {
        padding: 5px;
      }
    }
    .fc-day-grid-event {
      margin: 0;
      padding: 5px;
      width: inherit;
    }
    .fc-toolbar {
      h2 {
        font-size: 20px;
        line-height: 35px;
        font-weight: bold;
      }
    }
    .basic-calendar {
      .external-events {
        margin-top: 0px;
      }
    }
  }
  .calendar-wrap {
    .fc-basic-view {
      .fc-day-number {
        padding: 0 0px !important;
      }
    }
    .fc-events-container {
      margin-top: 0px;
    }
    .fc-events-container {
      h6,
      .checkbox {
        text-align: right;
      }
    }
    .checkbox {
      label {
        margin-top: 0;
      }
    }
    .fc {
      .fc-toolbar {
        display: block !important;
      }
      button {
        font-size: 14px !important;
        padding: 0 5px !important;
      }
      .fc-left {
        margin-bottom: 10px;
      }
      .fc-toolbar {
        > div {
          display: block !important;
          float: none !important;
        }
        > * {
          :first-child {
            font-size: 18px;
          }
          > * {
            float: none !important;
          }
        }
      }
      margin-top: 0px;
    }
  }
  .user-card{
    .custom-card{
      .profile-details{
        margin-top: 15px;
      }
      .card-header{
        img{
          margin-top: -140px;
        }
      }
    }
  }
  .user-profile{
    .profile-post{
      .post-body{
        .post-react{
          margin-bottom: 12px;
        }
      }
    }
  }  
  .chat-box{
    .chat-right-aside{
      .chat{
        .chat-msg-box{
          margin-bottom: 50px;
        }
      }
    }
  }   
  .product-page-main{
    .proorder-lg-1{
      order: 0;
    }
  } 
  .contact-editform {
    padding-left: 20px !important;
  }
  .page-wrapper {
    .nav-right {
      .nav-menus {
        margin-right: 57px;
      }
    }
    &.compact-wrapper {
      .nav-right {
        .nav-menus {
          margin-right: 0;
        }
      }
    }
    &.only-body {
      .page-header {
        .header-wrapper {
          .nav-right {
            > ul {
              > li {
                margin-right: -2px;
              }
            }
            .nav-menus {
              margin-right: -10px;
            }
          }
        }
      }
    }
  }
  .new-products {
    .owl-carousel {
      .product-box {
        &.row {
          > div {
            width: auto;
          }
        }
      }
    }
  }
  .profile-media {
    .media-body {
      display: none;
    }
  }
  .form-inline {
    .form-group:not(.form-control-search) {
      width: 100%;
      + .form-group {
        margin-top: 10px;
      }
      input {
        width: 100%;
      }
    }
  }
  .credit-card {
    .text-center {
      img {
        margin-bottom: 20px;
      }
    }
  }
  .footer-copyright {
    ~ div {
      text-align: center;
      p {
        float: none;
        margin-top: 5px;
      }
    }
  }
  // button page //
  .btn-showcase {
    text-align: center;
  }
  // social app page css
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          right: 25px;
          top: -19px;
          li {
            .social-icon {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
    }
  }
  .tabs-scoial {
    &.border-tab {
      &.nav-tabs {
        display: flex;
      }
    }
  }
  // icon-compact layout page
  .my-gallery {
    figure {
      &:nth-child(9),
      &:nth-child(10) {
        margin-bottom: 20px;
      }
    }
  }
  // project list //
  .project-list {
    .btn {
      margin-top: 5px;
    }
  }
  // chat
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-message {
          .text-box {
            .btn {
              font-size: 0;
              &:before {
                position: absolute;
                content: "\e661";
                font-family: $font-themify;
                font-size: 18px;
                top: 11px;
                left: 20px;
              }
            }
          }
        }
      }
    }
    .border-tab {
      &.nav-tabs {
        display: flex;
      }
    }
    .chat-menu {
      .nav-tabs {
        .nav-item {
          a {
            height: 50px;
          }
        }
      }
    }
    .chat-history {
      .call-content {
        > div {
          padding-top: 40px;
          height: auto;
        }
      }
      .receiver-img {
        margin-bottom: unset;
      }
    }
    .chat-left-aside {
      .people-list {
        height: auto;
      }
    }
  }
  .call-chat-sidebar {
    max-width: 100%;
    width: 100%;
    flex-basis: auto;
    .people-list {
      height: auto;
    }
  }
  .chat-left-aside {
    .people-list {
      ul {
        max-height: 230px;
      }
    }
  }
  .clockpicker-align-top {
    left: 55px !important;
    top: 470px !important;
    .arrow {
      display: none;
    }
  }
  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 415px;
      left: 0;
      top: 61px;
      padding: 20px;
    }
    .lg-mt {
      margin-top: 15px;
    }
  }
  .reader {
    margin-top: 15px;
    height: auto !important;
  }
  .range-slider {
    &.theme-form {
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  .note-editor {
    .btn-group {
      .btn {
        font-size: 11px;
      }
    }
  }
  .user-status {
    tbody {
      tr {
        td {
          min-width: 284px;
          & + td {
            min-width: unset;
            & + td {
              min-width: 100px;
            }
          }
        }
      }
    }
  }
  .text-md-end {
    text-align: right;
  }
  .star-ratings {
    .stars {
      padding: 0;
    }
  }
  .form-inline {
    &.d-inline-block {
      display: block !important;
      width: 100%;
      .form-control {
        margin-bottom: 10px;
      }
    }
  }
  .reset-password-box {
    width: 500px;
    margin: 0 auto;
    .card {
      padding: 20px;
    }
  }
  .user-card {
    .custom-card {
      .card-profile {
        img {
          height: 90px;
        }
      }
    }
  }
  .custom-card {
    .card-profile {
      img {
        height: 136px;
        bottom: 0;
      }
    }
    .card-social {
      li {
        &:nth-child(n + 2) {
          margin-left: 10px;
        }
        a {
          padding: 10px;
          width: 30px;
          height: 30px;
          font-size: 14px;
        }
      }
    }
  }
  .error-wrapper {
    .maintenance-icons {
      li {
        i {
          color: $light-color;
          font-size: 30px;
        }
        &:nth-child(2) {
          i {
            font-size: 60px;
            margin-top: -10px;
            margin-left: 70px;
          }
        }
        &:nth-child(3) {
          i {
            font-size: 90px;
            margin-right: 80px;
          }
        }
      }
    }
  }
  .authentication-main {
    .auth-innerright {
      .social-media {
        li {
          font-size: 14px;
        }
      }
      min-height: unset;
      padding: 0;
    }
  }
  .loader-box {
    justify-content: center;
  }
  .btn-group-wrapper {
    text-align: center;
    .m-b-30 {
      margin-bottom: 15px !important;
    }
  }
  .btn-group-showcase {
    text-align: center;
    .btn-group {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  .btn-group {
    .btn {
      font-size: 14px;
      padding: 0.375rem 1.3rem;
    }
  }
  //general widget page
  .user-status {
    &.product-chart {
      max-height: 100%;
    }
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: 124px;
            }
          }
        }
      }
    }
  }
  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 350px;
    }
  }
  /* font-awesom icon page */
  .icon-hover-bottom {
    .form-group {
      input {
        min-width: 270px;
      }
    }
  }
  //user profile
  .user-profile {
    .hovercard {
      .cardheader {
        height: 350px;
      }
      .info {
        padding: 35px;
      }
    }
  }
  //blog
  .blog-single {
    .comment-box {
      .media {
        h6 {
          margin-bottom: 10px;
        }
        img {
          margin-right: 30px;
        }
      }
      .comment-social {
        margin-bottom: 10px;
      }
    }
  }
  .blog-box {
    .blog-details-main {
      .blog-social {
        li {
          padding: 0 12px;
        }
      }
    }
  }
  .learning-box .details-main {
    padding: 20px;
  }
  //material tab
  .tabbed-card {
    ul {
      &.border-tab {
        margin-bottom: 15px;
        &.nav-tabs {
          top: unset;
          .nav-item {
            .nav-link {
              padding: 5px 15px 10px;
            }
          }
        }
      }
      padding: 10px 0 5px 0;
      position: relative;
      width: 100%;
      left: 0;
    }
  }
  //search
  .search-page {
    #image-links {
      .info-block {
        &.m-t-30 {
          margin-top: 0 !important;
        }
      }
    }
  }
  //authentication
  .authentication-main {
    .authentication-box {
      width: 100%;
    }
    .auth-innerright {
      display: inherit;
    }
  }
  //default dashboard
  .candidcahrt {
    height: 250px;
  }
  //chart widget
  .bar-chart-widget {
    .earning-details {
      i {
        right: 0;
      }
    }
  }
  .bottom-content {
    padding: 10px;
    p {
      font-size: 12px;
    }
  }
  // icons
  .icon-hover-bottom {
    .icon-popup {
      display: flex;
      > .icon-last {
        display: block;
        margin-top: 15px;
      }
      div {
        .flag-icon {
          width: 30px;
          height: auto;
        }
      }
    }
    .form-group {
      input {
        display: block;
        margin-bottom: 10px;
        width: auto !important;
      }
    }
  }
  //footer
  .footer-links {
    text-align: center;
  }
  .footer-copyright {
    text-align: center !important;
  }
  .sm-left-text {
    text-align: left !important;
  }
  // alert page
  .alert-dismissible {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 294px;
    }
  }
  // button group page
  .button-group-mb-sm {
    margin-bottom: 5px;
  }
  // table components page
  .card-block {
    .table-responsive {
      .table {
        tbody {
          tr {
            td {
              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100px;
              }
            }
          }
        }
      }
      .progress-content {
        tbody {
          tr {
            td {
              span {
                text-overflow: ellipsis;
                white-space: normal;
                max-width: 50px;
              }
            }
          }
        }
      }
      .checkbox-td-width,
      .radio-first-col-width {
        tbody {
          tr {
            td {
              min-width: 200px !important;
            }
          }
        }
      }
    }
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: auto;
        }
      }
    }
    .chat-menu {
      .nav-tabs {
        .nav-item {
          width: 32.33%;
        }
      }
      .people-list {
        ul.list {
          max-height: 340px;
          margin-bottom: 15px;
        }
      }
      #info-profile,
      #info-contact {
        margin-bottom: 15px;
      }
    }
  }
  // user-profile page
  .hovercard {
    .info {
      .follow {
        .text-md-end {
          text-align: center;
        }
      }
    }
  }
  .profile-img-style {
    .like-comment-sm-mb {
      margin-bottom: 20px;
    }
  }
  // forget-password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          margin-top: 15px !important;
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 42%;
        }
      }
    }
  }
  .product-filter {
    .product-box {
      flex-wrap: nowrap;
      .product-img {
        width: 100px;
      }
      .product-details {
        padding: 0 !important;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .page-wrapper {
    &.compact-wrapper {
      .page-header {
        .left-side-header{
          div.searchbar{
            width: auto !important;
            .resp-serch-box{
              svg{
                vertical-align: middle;
                width: 20px;
                height: 20px;
              }
            }
            .search-form{
              .Typeahead-menu{
                border-radius: 15px;
                overflow: scroll !important;
              }
            }
          }
        }
        .header-wrapper {
          .nav-right {
            margin-left: auto;
          }
        }
      }
    }
  }
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          .resp-serch-input {
            .search-form {
              input {
                background-color: $white;
                border: 1px solid #d8e0ff;
              }
            }
          }
        }
        .search-form {
          top: 40px;
          position: absolute;
          transition: all linear 0.3s;
          left: 0;
          transform: translateY(-35px) scaleY(0);
          opacity: 0;
          visibility: hidden;
          background-color: $white;
          width: 180px;
          margin-left: 0;
          border-radius: 25px;
          .input-group-text {
            display: none;
            padding: 0;
          }
          input {
            background-color: transparent !important;
            border-radius: 25px !important;
          }
          &.open {
            transform: translateY(0px) scaleY(1);
            opacity: 1;
            visibility: visible;
            transition: all linear 0.3s;
          }
          input {
            padding: 10px;
            background-color: $light-2;
          }
        }
      }
    }
  }
  .product-page-main {
    .border-tab.nav-tabs .nav-item {
      width: auto !important;
    }
  }
  .products-total {
    margin-bottom: 10px;
  }
  // product page //
  .product-page-details {
    h3 {
      font-size: 18px;
    }
  }
  .grid-options ul li {
    display: none;
  }
  // product page
  .feature-products {
    .filter-toggle {
      padding: 8px 15px;
      margin-left: 10px;
      border-radius: 5px;
      background-color: rgba($primary-color, 0.05);
      color: var(--theme-default);
    }
    span {
      font-size: 12px;
      &.f-w-600 {
        padding-top: 8px;
        display: inline-block;
      }
    }
  }
  .grid-options {
    ul {
      li {
        display: none;
      }
    }
  }
  .product-wrapper {
    &.sidebaron {
      .product-grid {
        .product-wrapper-grid {
          margin-left: 0;
        }
      }
    }
  }
  .product-filter {
    .product-box {
      flex-wrap: nowrap;
      .product-img {
        width: 100px;
      }
      .product-details {
        padding: 0 !important;
      }
    }
  }
  .product-main {
    &.border-start {
      border: none !important;
    }
  }
  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-top {
          .d-flex {
            padding-bottom: 0;
          }
          .float-end {
            float: left !important;
          }
        }
        .email-wrapper {
          .attachment {
            ul {
              li {
                &:last-child {
                  padding-right: 0;
                }
                img {
                  width: 90px;
                  height: 90px;
                }
              }
            }
          }
        }
      }
    }
  }
  .email-wrap {
    .email-right-aside {
      .email-profile {
        overflow-x: auto;
        .inbox {
          width: 700px;
          p {
            width: calc(100% - 200px);
            max-width: 500px;
          }
        }
      }
    }
  }
  .social-tab {
    display: block;
    ul {
      justify-content: center;
      margin-bottom: -10px;
      li {
        display: inline-block;
        margin-bottom: 10px;
      }
    }
    .input-group {
      margin-top: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  .social-app-profile {
    .social-status {
      .media {
        img {
          width: 40px !important;
        }
        .social-status {
          bottom: 10px;
          left: 35px;
        }
      }
    }
  }
  .user-profile {
    .profile-header {
      .userpro-box {
        .avatar {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}
@media only screen and (max-width: 710px) {
  .page-wrapper {
    &.compact-wrapper {
      .nav-right {
        .nav-menus {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 575.98px) and (min-width: 480px) {
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          .onhover-show-div {
            li {
              .media {
                .notification-img {
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 575.98px) {
  .rating{
    .text-end{
      text-align: left !important;
      margin-top: 6px;
    }
  }
  .listings{
    .tab-javascript{
      margin-bottom: 16px;
      ul{
        li{
          text-align: center;
        }
      }
    }
  }
  .dismiss-text {
    .alert-dismissible {
      p {
        max-width: 160px;
      }
      .btn-close {
        top: 0;
      }
    }
  }
  .customers.avatar-group{
    margin-top: 0;
  }
  .listing{
    .card-body{
      .row{
        > div{
          + div{
            margin-top: 20px;
          }
        }
      }
    }
  }
  .socialprofile{
    .social-group{
      ul{
        display: inline-block;
      }
    }
  }
  .datetime-picker{
    .theme-form{
      .row{
        > div{
          margin-top: 0;
        }
      }
      label.text-end{
        text-align: left !important;
      }
    }
  }
  .mega-vertical{
    .row{
      > div{
        &:last-child{
          .card{
            margin-top: 20px !important;
          }
        }
      }
    }
  }
  .todo{
    .todo-list-wrapper{
      #todo-list{
        li{
          .task-container{
            .task-action-btn{
              .action-box{
                .icon{
                  width: 18px;
                  height: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
  .list-persons{
    .profile-mail{
      .email-general{
        padding-top: 30px;
      }
    }
  }  
  .product-page-main{
    p{
      + p{
        margin-top: 10px !important;
      }
    }
  }
  .user-profile{
    .profile-post{
      .post-body{
        .img-container{
          .my-gallery{
            > div{
              + div{
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
    .avatar-showcase{
      .friend-pic{
        margin-top: 0;
      }
    }
  }
  .order-box{
    .total{
      margin-bottom: 15px;
    }
  }
  .ProfileCard-details{
    padding-left: 26px;
  }
  .page-wrapper{
    &.compact-wrapper{
      .page-header{
        .left-side-header{
          div.searchbar{
            .resp-serch-box{
              svg{
                width: 18px;
              }
            }
          }
        }
      }
    }
    .page-header{
      .header-wrapper{
        .nav-right{
          .onhover-show-div.bookmark-flip{
            .flip-card{
              width: 256px;
              .flip-card-inner{
                .front, .back{
                  width: 256px;
                }
              }
            }
          }
          ul{
            li{
              h6.txt-dark{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .page-body-wrapper{
      .page-title .breadcrumb{
        li{
          font-size: 12px;
        }
      }
    }
  }   
  .page-wrapper.only-body {
    .page-header {
      .header-wrapper {
        .nav-right {
          right: 30px;
          .nav-menus {
            margin-right: 0;
          }
        }
      }
    }
  }
  // product page
  .feature-products {
    .text-end {
      text-align: left !important;
    }
    .select-options {
      float: left;
      margin-right: 10px;
    }
  }
  .product-page-main {
    padding: 15px;
    .m-t-15 {
      margin-top: 0 !important;
      .btn {
        margin-top: 10px;
      }
    }
    .pro-group {
      .pro-shop {
        button {
          &:last-child {
            margin-top: 15px;
          }
        }
      }
    }
  }
  // product page //
  .product-color {
    li {
      width: 15px;
      height: 15px;
    }
  }
  ul.pro-services {
    li {
      h5 {
        font-size: 14px;
      }
      p {
        font-size: 13px;
      }
    }
  }
  .product-wrapper {
    .pagination-content {
      justify-content: flex-start;
      margin-top: 8px;
    }
  }
  .d-inline-block {
    float: left !important;
    margin-top: 10px;
  }
  .product-page-main {
    padding: 15px;
    .m-t-15 {
      margin-top: 0 !important;
      .btn {
        margin-top: 10px;
      }
    }
    .pro-group {
      .pro-shop {
        a{
          button{
            margin-top: 0 !important;
          }
        }
        button {
          &:last-child {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .date-picker {
    .theme-form {
      > .row {
        > div {
          &:last-child {
            margin-top: 0;
          }
        }
      }
    }
  }
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right.right-header {
          ul {
            li {
              .mode {
                width: 20px;
              }
            }
          }
        }
      }
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 15px;
    }
    .comments-box {
      .media {
        .m-r-20 {
          margin-right: 10px;
        }
        .media-body {
          .input-group {
            .input-group-append {
              .btn {
                padding: 6px;
              }
            }
          }
        }
      }
    }
    .comment-number {
      i {
        margin-right: 15px;
      }
    }
  }
  // user profile
  .user-profile {
    .photos {
      ul {
        li {
          width: 18%;
        }
      }
    }
    .profile-post {
      padding: 20px;
      .post-body {
        figure {
          margin-bottom: 20px;
        }
        .post-react {
          ul {
            li {
              img {
                width: 20px;
                height: 20px;
              }
              &:nth-child(n + 2) {
                margin-left: -15px;
              }
            }
          }
          h6 {
            margin-left: 10px;
            font-size: 13px;
          }
        }
      }
    }
    .profile-header {
      padding: 0;
      height: auto;
      .profile-img-wrrap {
        display: block;
        height: auto;
        img.media{
          border-radius: 15px 15px 0 0;
        }
      }
      .userpro-box {
        max-width: 100%;
        border-radius: 0 0 15px 15px;
      }
    }
  }
  .search-page {
    .info-block {
      display: block;
    }
  }
  .form-bookmark {
    .row {
      > div {
        .row {
          > .col-sm-6 {
            + .col-sm-6 {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .chat-box {
    .chat-message {
      .btn {
        padding: 6px 22px;
      }
    }
  }
  .btn {
    padding: 6px 27px;
  }
  .alert-theme {
    min-width: 380px;
  }
  .knowledgebase-bg {
    height: 250px;
  }
  .knowledgebase-search {
    width: calc(100% - 30px);
    left: 15px;
    height: calc(100% - 30px);
    background-color: rgba($white, 0.7);
    .form-inline {
      width: 90%;
      padding: 3px 45px;
      svg {
        top: 10px;
      }
    }
    > div {
      width: 100%;
      text-align: center;
      padding: 15px;
      border-right: 10px;
      h3 {
        font-size: 20px;
        margin-bottom: 0;
      }
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  // invoice template page
  .invoice {
    .text-md-end {
      margin-top: 10px;
    }
    .text-end {
      text-align: right;
    }
    .text-xs-center {
      text-align: center;
    }
  }
  // Login page
  .bookmark {
    ul {
      li {
        a {
          svg {
            height: 16px;
            width: 16px;
          }
        }
        & + li {
          margin-left: 5px;
        }
      }
    }
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    padding: 4px;
  }
  .page-header {
    .header-wrapper {
      .nav-right {
        .cart-box,
        .notification-box {
          .badge {
            font-size: 10px;
          }
        }
      }
    }
  }
  .social-app-profile {
    .hovercard {
      .user-image {
        .avatar {
          margin-top: -56px;
          img {
            width: 100px;
            height: 100px;
          }
        }
        .icon-wrapper {
          left: 52%;
          top: 10px;
          height: 30px;
          width: 30px;
          font-size: 15px;
          &:hover {
            font-size: 12px;
          }
        }
        .share-icons {
          right: 15px;
          li {
            margin-right: 0px;
          }
        }
      }
      .info {
        .tabs-scoial {
          .user-designation {
            border-top: none;
            border-bottom: none;
            margin-bottom: 0;
            padding: 0px 0px;
          }
        }
      }
    }
  }
  .socialprofile {
    .social-btngroup {
      margin: 15px 0;
    }
    .social-group {
      margin-top: 15px;
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-social {
          margin-bottom: 15px;
        }
      }
    }
    .media {
      margin-bottom: 15px;
    }
  }
  .new-users-social {
    margin-bottom: 15px;
  }
  .page-wrapper {
    .page-body-wrapper {
      .page-title > .row {
        .col-6 {
          &:first-child {
            display: block;
            h3 {
              padding-right: 0;
            }
          }
        }
      }
    }
    .page-header {
      .header-wrapper .nav-right ul {
        li {
          i {
            font-size: 14px !important;
          }
          svg {
            width: 16px;
          }
        }
      }
    }
    .page-body-wrapper {
      .page-title {
        .breadcrumb {
          justify-content: flex-start;
          padding: 10px 0 0;
          ol.breadcrumb{
            padding-top: 0;
          }
        }
      }
    }
    .nav-right .nav-menus {
      margin-right: 45px;
    }
    &.compact-wrapper {
      .page-header {
        .header-wrapper {
          .logo-wrapper {
            display: none;
          }

          .toggle-sidebar {
            margin-left: 0;
            padding-left: 0;
            border-left: none;
            border-right: 1px solid #ddd;
            padding-right: 8px;
            margin-right: 8px;

            svg {
              width: 18px;
            }
          }
        }
      }
    }
    .page-header {
      .header-wrapper {
        padding: 12px 15px;
        .nav-right {
          > ul {
            top: 58px;
            .search-form {
              i {
                display: none;
              }
            }
          }
          &.right-header {
            ul {
              li {
                &.px-0 {
                  padding: 6px !important;
                }
              }
            }
          }
          > ul {
            li {
              margin-right: 1px;
              .flag-icon {
                font-size: 14px !important;
              }
              .profile-media {
                img {
                  width: 24px;
                  margin-left: 0;
                }
              }
            }
          }
          .notification-dropdown {
            &.onhover-show-div {
              &:before,
              &:after {
                left: 24px !important;
                right: unset !important;
              }
            }
          }
          .chat-dropdown {
            left: -52px;
            width: 300px;
            &:after,
            &:before {
              left: 58px !important;
            }
          }
        }
      }
    }
    .page-body-wrapper {
      .page-title {
        .btn-group {
          .btn {
            padding: 8px 30px;
          }
        }
      }
    }
    &.compact-wrapper {
      .page-body-wrapper {
        .page-body {
          margin-top: 54px;
        }
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              height: calc(100vh - 79px);
            }
          }
        }
      }
    }
  }
  .alert-dismissible {
    padding-right: 30px;
    p {
      max-width: 145px;
    }
  }
  .F {
    display: none;
  }
  .m-r-30 {
    margin-right: 15px;
  }
  // cart page //
  .wishlist {
    .text-end {
      text-align: left !important;
    }
  }
  // general widget page css
  .mobile-clock-widget {
    #date {
      margin-top: 15px;
    }
  }
  .social-widget-card {
    .b-b-light {
      padding: 15px;
      margin-bottom: 15px;
    }
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        top: 34px;
      }
    }
  }
  .cal-date-widget {
    .cal-datepicker {
      margin-top: 15px;
    }
  }
  // Add post page css
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-right: 15px;
        }
      }
    }
    .dropzone {
      margin-bottom: 15px;
    }
  }
  // avatar page css
  .customers {
    &.avatar-group {
      margin-right: 15px;
    }
  }
  .vertical-mobile-sidebar {
    top: 19px;
  }
  //notify index page
  .alert-theme {
    i {
      margin-right: 10px !important;
    }
    button {
      top: 0px !important;
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        right: -30px;
      }
    }
  }
  .clockpicker-align-top {
    left: 45px !important;
    top: 406px !important;
  }
  .gallery {
    margin-bottom: -15px;
  }
  .my-gallery {
    figure {
      margin-bottom: 15px;
      &:nth-child(9),
      &:nth-child(10) {
        margin-bottom: 15px;
      }
    }
    &.gallery-with-description {
      figure {
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
  }
  #aniimated-thumbnials {
    figure {
      &:nth-child(12),
      &:nth-child(11),
      &:nth-child(10),
      &:nth-child(9) {
        margin-bottom: 15px;
      }
    }
  }
  .photoswipe-pb-responsive {
    padding-bottom: 30px !important;
  }
  // social app page css
  .avatar-showcase {
    .pepole-knows {
      text-align: center;

      ul {
        li {
          margin-right: 14px;
        }
      }
    }
  }
  .socialprofile {
    .social-btngroup {
      margin: 15px 0;
    }
    .social-group {
      margin-top: 15px;
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-social {
          margin-bottom: 15px;
        }
      }
    }
    .media {
      margin-bottom: 15px;
    }
  }
  .new-users-social {
    margin-bottom: 15px;
  }
  .tabs-scoial {
    position: relative;
    padding-top: 80px;
    li {
      &:nth-child(3) {
        position: absolute;
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    &.border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 10px;
            height: 60px;
          }
        }
      }
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 15px;
    }
    .comments-box {
      .media {
        .m-r-20 {
          margin-right: 10px;
        }
        .media-body {
          .input-group {
            .input-group-append {
              .btn {
                padding: 6px;
              }
            }
          }
        }
      }
    }
    .comment-number {
      i {
        margin-right: 15px;
      }
    }
  }
  .social-chat {
    margin-top: 15px;
    .media-body {
      padding: 15px;
    }
    .your-msg,
    .other-msg {
      margin-bottom: 15px;
    }
  }
  .social-network {
    span {
      margin-bottom: 15px;
    }
  }
  .social-list {
    .media {
      margin-bottom: 15px;
    }
  }
  .details-about + .details-about {
    margin-top: 15px;
  }
  .your-details-xs {
    margin-top: 15px;
  }
  .social-header {
    h5 {
      span.pull-right {
        float: right !important;
        margin-top: 0 !important;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 15px;
      }
    }
  }
  .user-profile{
    .photos{
      ul{
        li{
          &:nth-child(3n){
            margin-right: 10px;
          }
        }
      }
    }
  }
  .photos {
    ul {
      li {
        width: 26%;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
  // chat page //
  .chat-box {
    .chat-history {
      .call-content {
        > div {
          padding-top: 30px;
        }
      }
    }
  }
  // helper classes page start
  .helper-classes {
    padding: 15px;
  }
  .nav-tabs {
    .nav-item {
      &.show {
        .nav-link {
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
    .nav-link {
      border-top: 0;
      border-bottom: 0;
      &:hover,
      &.active,
      &:focus {
        border-top: 0;
        border-bottom: 0;
      }
    }
  }
  .border-tab {
    &.nav-tabs {
      .nav-item {
        width: 100% !important;
      }
    }
    .weather-widget-two {
      .bottom-whetherinfo {
        .whether-content {
          top: 35px;
        }
      }
    }
    .social-widget-card {
      .b-b-light {
        padding: 15px;
        margin-bottom: 15px;
      }
    }
    .ecommerce-widget {
      .progress-showcase {
        margin-top: 15px;
      }
    }
  }
  .nav {
    display: inherit;
    text-align: center;
    border-bottom: none;
  }
  // tab material start
  .border-tab.nav-left {
    .nav-link {
      text-align: center;
      border-left: none;
    }
  }
  .border-tab.nav-right {
    .nav-link {
      text-align: center;
      border-right: none;
    }
  }
  .border-tab.nav-tabs {
    margin-bottom: 10px;
    display: block;
  }
  .tab-content {
    ~ .nav-tabs {
      &.border-tab {
        margin-top: 10px;
      }
    }
  }
  // tab material end
  .nav-pills {
    text-align: center;
  }
  .crm-overall {
    margin: 0 -15px -15px;
  }
  .product-color {
    li {
      width: 15px;
      height: 15px;
    }
  }
  .product-social {
    li {
      a {
        width: 30px;
        height: 30px;
        font-size: 13px;
      }
      &:nth-child(n + 2) {
        margin-left: 5px;
      }
    }
  }
  .product-page-main {
    padding: 15px;
    .m-t-15 {
      .btn {
        margin-top: 10px;
      }
    }
  }
  .todo {
    .action-box {
      &.large {
        height: 25px;
        width: 25px;
        .icon {
          font-size: 14px;
          vertical-align: -3px;
        }
      }
    }
    .todo-list-wrapper {
      #todo-list {
        li {
          .task-container {
            .task-label {
              font-size: 14px;
            }
          }
        }
      }
      .mark-all-tasks {
        right: 20px;
      }
    }
  }
  #nav-tabContent {
    margin-top: 15px;
  }
  .reset-password-box {
    width: 430px;
  }
  .auth-bg {
    padding: 25px 15px;
  }
  .date-picker {
    .text-end {
      text-align: left !important;
    }
  }
  ul {
    &.pagination {
      li {
        &:before {
          line-height: 3;
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .user-profile {
        .profile-img-style {
          padding: 15px;
          .img-container {
            margin-top: 15px;
          }
        }
        hr {
          margin: 15px 0;
        }
        .like-comment {
          margin-top: 15px;
        }
      }
    }
  }
  .user-profile {
    .order-sm-0 {
      order: -1;
    }
  }
  .icon-hover-bottom {
    .form-group {
      display: block !important;
      margin-bottom: 0;
      input {
        width: 100% !important;
      }
    }
    .icon-popup {
      padding: 15px;
    }
    svg {
      &.climacon {
        height: 50px;
        width: 50px;
      }
    }
  }
  .ace-editor {
    height: 320px;
  }
  .gallery {
    > a {
      margin-bottom: 15px;
      text-align: center;
    }
  }
  .card {
    .card-header {
      h5 {
        font-size: 17px;
      }
    }
  }
  .theme-form {
    .form-group {
      margin-bottom: 15px;
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .default-according {
        .card {
          .card-header,
          .card-body,
          .card-footer {
            padding: 0.75rem 1.25rem;
          }
        }
      }
      .card {
        margin-bottom: 20px;
        .card-header,
        .card-body,
        .card-footer {
          .tab-content {
            .m-t-30 {
              margin-top: 20px !important;
            }
            .m-b-30 {
              margin-bottom: 20px !important;
            }
          }
        }
      }
      .page-body {
        padding: 0 !important;
      }
      .page-title {
        padding-top: 15px;
        padding-bottom: 15px;
        .row {
          h3 {
            font-size: 20px;
          }
        }
      }
    }
  }
  /* alert responsive css start here */
  $alert-name: primary, secondary, success, danger, warning, info, light, dark;
  $alert-color: var(--theme-default), var(--theme-secondary), $success-color,
    $danger-color, $warning-color, $info-color, $light-color, $dark-color;
  @each $var in $alert-name {
    $i: index($alert-name, $var);
    .alert-#{$var}.inverse {
      background-color: transparent;
    }
  }
  //user profile
  .user-profile {
    hr {
      margin: 15px 0;
    }
    .hovercard {
      .cardheader {
        height: 300px;
      }
      .info {
        padding: 20px;
        .user-designation {
          border-top: 1px solid $light-color;
          border-bottom: 1px solid $light-color;
          margin-bottom: 15px;
          padding: 15px 0px;
        }
      }
      .social-media {
        a {
          margin-right: 0px;
          font-size: 16px;
        }
      }
      .follow {
        .follow-num {
          font-size: 20px;
        }
      }
    }
    .profile-img-style {
      .pictures {
        img {
          margin-bottom: 10px;
        }
      }
    }
  }
  //user card
  .custom-card {
    .card-footer {
      > div {
        h6 {
          font-size: 14px;
          font-weight: 600;
        }
        h3 {
          font-size: 24px;
        }
      }
    }
  }
  // megaoption page responsive scss
  .mega-inline {
    display: block;
  }
  .megaoptions-border-space-sm {
    padding-bottom: 30px !important;
  }
  //blog
  .blog-box {
    &.blog-shadow {
      &:before {
        box-shadow: inset 0px -100px 100px -13px rgba(0, 0, 0, 0.6);
      }
    }
    .blog-details {
      p,
      .single-blog-content-top {
        margin-top: 15px;
      }
      h4 {
        font-size: 16px;
        margin-top: 10px;
      }
      .blog-social {
        li {
          font-size: 12px;
          font-weight: 500;
          &:nth-child(n + 2) {
            padding-left: unset;
            margin-left: 10px;
            border-left: none;
          }
        }
      }
    }
  }
  .comment-box {
    h4 {
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
    ul {
      ul {
        margin-left: 30px;
      }
    }
    li {
      margin-bottom: 15px;
      .media {
        display: block;
        img {
          height: 50px;
          width: 50px;
          padding: 3px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .blog-box.blog-shadow {
    .blog-details {
      padding: 15px;
      p {
        margin-bottom: 0;
      }
      h4 {
        margin-bottom: 10px;
      }
    }
  }
  .blog-box {
    .blog-details {
      padding: 20px;
    }
    .blog-date {
      span {
        font-size: 25px;
      }
    }
  }
  //error and maintenace
  .error-wrapper {
    padding: 20px 0;
    .btn {
      margin-top: 15px;
    }
    .img-100 {
      width: 50px !important;
    }
    .error-heading {
      margin-top: 20px;
      .cloud-second {
        margin-top: -60px;
      }
      .headline {
        font-size: 150px;
      }
    }
    .maintenance-heading {
      .cloud-second {
        display: none;
      }
      .headline {
        font-size: 25px;
        margin-top: -10%;
        letter-spacing: 2px;
      }
    }
  }
  //coming soon
  .comingsoon {
    .comingsoon-inner {
      .coming-soon-bottom-link {
        margin-top: 20px;
        line-height: 1;
      }
      h5 {
        margin-bottom: 20px;
        margin-top: 20px;
      }
      .countdown {
        padding: 20px 0px;
        ul li {
          margin: 0 7px;
        }
        .title {
          font-size: 12px;
        }
        .time {
          width: 45px;
          height: 45px;
          line-height: 45px;
          font-size: 16px;
          margin: 0 auto;
        }
      }
    }
  }
  //chart
  .status-widget {
    .text-sm-end {
      text-align: right;
    }
  }
  //mega menu
  .mega-menu {
    padding-left: 15px;
    .onhover-show-div {
      height: 405px;
      left: 0;
      top: 61px;
      padding: 15px;
    }
    .lg-mt {
      margin-top: 10px;
    }
  }
  // navs page
  .nav-md-mt {
    margin-top: 15px;
  }
  .navs-icon {
    padding: 15px;
    .main-section {
      padding-top: 15px;
    }
    .separator {
      margin: 15px 0;
    }
  }
  .nav-list {
    padding: 15px;
  }
  .navs-dropdown {
    button {
      margin-top: 15px;
    }
    .onhover-show-div {
      top: 64px;
    }
  }
  .lg-mt {
    margin-top: 15px;
  }
  // scroll reval
  #aniimated-thumbnials {
    a {
      &:last-child {
        img {
          margin-bottom: 15px;
        }
      }
    }
  }
  // tag-pills page
  span:last-child.tag-pills-sm-mb {
    margin-top: 3px;
  }
  // steps page
  .steps-md-mt {
    margin-top: 15px;
  }
  .steps-sizing-sm-mb {
    margin-bottom: 15px;
  }
  // support ticket page
  .ecommerce-widget {
    .progress-showcase {
      margin-top: 15px;
    }
  }
  // forget-password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          margin-top: 13px !important;
        }
      }
    }
  }
  // gallery-with-description page
  .gallery-with-description {
    a {
      > div {
        margin-bottom: 15px;
      }
    }
  }
  // gallery hover page
  .gallery-img-mb-sm {
    margin-bottom: 15px;
  }
  // login page
  .authentication-main {
    padding: 30px 15px;
  }
  .onhover-dropdown {
    .onhover-show-div{
      transform: none;
    }
    &:hover {
      .onhover-show-div {
        opacity: 0;
        transform: none;
        visibility: hidden;
        position: fixed;
        width: 100%;
        left: 15px;
        top: 60px;
        &.active {
          opacity: 1;
          transform: none;
          visibility: visible;
          border-radius: 5px;
          overflow: hidden;
        }
      }
    }
  }
  .page-header {
    .header-wrapper {
      .nav-right {
        &.right-header {
          .cart-dropdown,
          .notification-dropdown {
            position: fixed;
            top: 60px;
            right: 15px !important;
          }
          ul {
            &.profile-dropdown {
              position: fixed;
              top: 60px;
              right: 15px !important;
            }
          }
        }
        .profile-dropdown {
          top: 43px;
        }
      }
    }
  }
  .tabbed-card {
    ul {
      right: 0px;
      left: unset;
    }
  }
  // dropdown-basic //
  .dropdown-basic {
    .btn-group {
      margin-right: unset;
    }
  }
  .single-content-social-media {
    text-align: left;
    ul {
      li {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
  }
  .resp-serch-input {
    .search-form {
      top: 50px !important;
    }
  }
  .status_toggle {
    img {
      width: 18px;
      height: 18px;
    }
  }
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .cart-dropdown {
          li {
            .media {
              .media-body {
                margin-right: 20px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .chart-block{
    .chart-overflow{
      &#pie-chart1{
        > div{
          height: 280px !important;
        }
      }
    }
  }
  .vertical-timeline-element-subtitle{
    font-size: 18px;
  }
  .ReactCrop{
    ~ div{
      > canvas{
        margin-top: 12px;
      }
    }
  }
  .range-slider{
    output{
      ~ div{
        > div[role=slider]{
          width: 26px !important;
          height: 26px !important;
          > div{
            height: 12px !important;
          }
        }
      }
    }
  }
  .both-side-scroll, .vertical-scroll{
    height: 280px;
  }
  .box-shadow-title{
    .sub-title{
      margin: 18px 0;
    }
  } 
  .tagandpills{
    .height-equal{
      span.badge{
        font-size: 0.75em;
      }
    }
  }
  .customers.avatar-group{
    margin-right: 0;
    ul{
      li{
        img{
          width: 35px !important;
        }
      }
    }
  }
  .user-card{
    .custom-card{
      .profile-details{
        margin-top: 5px;
      }
    }
  }  
  // kanban board
  .jkanban-container {
    .kanban-container {
      .kanban-board {
        width: 100%;
      }
    }
  }
  .chart-container{
    .apexcharts-canvas{
      text[text-anchor="end"]{
        font-size: 13px;
      }
    }
  }
  .page-wrapper{
    .page-header{
      .header-wrapper{
        .nav-right{
          .notification-dropdown{
            li{
              .media{
                .media-body{
                  margin-left: 52px;
                }
              }
            }
          }
          .onhover-show-div{
            &.bookmark-flip{
              .flip-card{
                .flip-card-inner{
                  .front{
                    a{
                      svg{
                        width: 20px;
                        height: 20px;
                      }
                    }
                  }
                }
              }
            }
            li{
              .media{
                .notification-img{
                  padding: 6px;
                }
              }
            }
          }
          .cart-nav{
            .cart-dropdown{
              .order-total{
                h4{
                  font-size: 13px
                }
              }
            }
          }
        }
      }
    }
  }      
  .customizer-links{
    &.open{
      right: 258px;
    }
    > .nav{
      padding: 8px;
    }
    .nav-link{
      .settings{
        i{
          padding: 5px;
        }
      }
    }
  }
  .customizer-contain{
    width: 260px;
    .customizer-header{
      padding: 15px 15px;
    }
    .customizer-body{
      padding: 15px 15px;
      select{
        width: 200px;
      }
      .main-layout{
        > li{
          width: 68px;
          height: 65px;
        }
      }
    }
  }
  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-wrapper {
          .attachment {
            ul {
              li {
                img {
                  width: 83px;
                  height: 83px;
                }
              }
            }
          }
        }
      }
    }
    .actions {
      margin-bottom: -10px;
      li {
        margin-bottom: 10px;
        .btn {
          padding: 5px 10px;
          font-size: 12px;
        }
      }
    }
    .email-wrapper {
      .right-download {
        float: none;
        margin-top: 5px;
      }
      h6 {
        float: none;
        margin-bottom: 8px !important;
      }
    }
  }

  // invoice template page
  .invoice {
    .text-md-end {
      margin-top: 10px;
    }
  }
  .product-wrapper {
    &.sidebaron {
      .product-grid {
        .product-wrapper-grid {
          margin-left: 0;
        }
      }
      .product-sidebar {
        .filter-section {
          .card {
            .left-filter {
              top: unset;
            }
          }
        }
      }
    }
  }
  .product-sidebar {
    transform: translateX(-300px) scaleX(0);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    height: 0;
    position: absolute;
    z-index: 1;
    width: 300px;
    &.open {
      transform: translateX(0px) scaleX(1);
      box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
      visibility: visible;
      opacity: 1;
      height: auto;
      top: -72px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid $transparent-color;
        border-right: 7px solid $transparent-color;
        border-bottom: 7px solid $white;
        right: 120px;
        top: -7px;
      }
    }
    .filter-section {
      .card-header {
        display: none;
      }
    }
  }
  .feature-products {
    .filter-toggle {
      padding: 14px 15px;
      margin-left: 10px;
      border-radius: 5px;
      h6 {
        font-weight: 600;
        font-size: 14px;
      }
    }
    span {
      &.f-w-600 {
        padding-top: 15px;
        display: inline-block;
      }
    }
    .text-end {
      text-align: left !important;
    }
  }
  .grid-options {
    ul {
      li {
        display: none;
      }
    }
  }
  .d-none-productlist {
    display: block;
    margin-right: 10px;
  }
  //social app
  .social-app-profile {
    .hovercard {
      .user-image {
        .share-icons {
          li {
            .social-icon {
              width: 30px;
              height: 30px;
            }
          }
        }
        .icon-wrapper {
          top: -5px;
        }
        .avatar {
          img {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }
  .chat-box {
    .chat-history {
      .call-icons {
        ul {
          li {
            width: 40px;
            height: 40px;
            padding: 7px;
            i {
              font-size: 18px;
            }
          }
        }
      }
    }
    .chat-right-aside {
      .chat {
        .chat-message {
          .text-box {
            .btn {
              font-size: 0;
              &:before {
                position: absolute;
                content: "\e661";
                font-family: $font-themify;
                font-size: 18px;
                top: 11px;
                left: 13px;
              }
            }
          }
        }
      }
    }
  }
  .default-dashboard-section {
    .news {
      .news-update {
        .media-body {
          h5 {
            font-size: 12px;
          }
        }
      }
    }
  }
  .default-according {
    &.default-according-page {
      .card {
        .card-header {
          .btn-link {
            padding: 12px;
          }
        }
      }
    }
  }
  .custom-profile {
    .card-social {
      .social-link {
        width: 35px;
        height: 35px;
        img {
          width: 16px;
          height: 16px;
        }
      }
      li {
        + li {
          margin-left: 10px;
        }
      }
    }
    .card-profile {
      img {
        height: 200px !important;
        bottom: 0 !important;
      }
    }
  }
  //user profile
  .user-profile {
    .profile-header {
      .userpro-box {
        padding: 15px;
      }
    }
    .follow {
      .follow-num {
        font-size: 16px;
      }
      span {
        font-size: 12px;
      }
      ul {
        &.follow-list {
          li {
            &:nth-child(n + 2) {
              margin-left: 10px;
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
  .page-wrapper.only-body {
    .page-header {
      .header-wrapper {
        .nav-right {
          display: none;
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        display: block;
        .product-img {
          width: 100%;
        }
      }
    }
  }
  .pagination-lg {
    .page-link {
      font-size: 1rem;
    }
  }
  .prooduct-details-box {
    .media-body {
      margin-left: 0rem !important;
    }
  }
  .box-layout {
    &.page-wrapper {
      .set-col-6,
      .set-col-3,
      .set-col-7,
      .set-col-5 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .knob-block {
    .chart-clock-main {
      margin: auto;
      width: auto;
      .clock-medium {
        position: absolute;
        left: 33px;
        top: 33px;
        canvas {
          width: 180px !important;
          height: 180px !important;
        }
      }
      .clock-small {
        left: 34px;
        top: 74px;
      }
      .clock-large {
        left: 0;
        top: 0;
        margin: 0 auto;
        canvas {
          width: 245px !important;
          height: 245px !important;
        }
      }
    }
    > div:not(.chart-clock-main) {
      canvas {
        width: 245px !important;
        height: 245px !important;
      }
    }
  }
  .inline-block-sm {
    display: inline-block;
  }
  .pagination-lg {
    .page-link {
      padding: 0.3rem 0.65rem;
    }
  }
  .nav-pills {
    li {
      width: 100%;
    }
  }
  .reset-password-box {
    width: 290px;
  }
  .icon-hover-bottom {
    .icon-first {
      margin-right: 10px;
    }
    .icon-popup {
      .icon-class {
        display: none;
      }
    }
  }
  .error-wrapper {
    .maintenance-heading {
      margin-top: 0;
    }
  }
  .custom-card {
    padding: 0;
    .card-footer {
      > div {
        h3 {
          font-size: 20px;
        }
      }
    }
    .profile-details {
      h6 {
        margin-bottom: 0;
      }
      a {
        h4 {
          font-size: 20px;
          margin-top: 3px;
        }
      }
    }
    .card-profile {
      img {
        height: 85px;
      }
    }
  }
  .user-card {
    .custom-card {
      .card-profile {
        img {
          height: 80px;
        }
      }
    }
  }
  .card {
    .card-header {
      .card-header-right {
        i {
          margin: 0 3px;
          font-size: 14px;
        }
      }
      > span {
        & + span {
          display: none;
        }
      }
    }
  }
  // dropdown page //
  .dropdown-basic {
    text-align: center;
  }
  .dropup-basic {
    text-align: center;
  }
  //blog
  .blog-box.blog-shadow {
    .blog-details {
      padding: 10px;
      h4 {
        font-size: 14px;
      }
    }
  }
  .cal-date-widget {
    padding: 30px;
  }
  .company-card {
    .details {
      .media {
        flex-wrap: wrap;
      }
    }
  }
  //mega menu
  .mega-menu {
    padding-left: 20px;
    padding-top: 3px;
    .onhover-show-div {
      height: 405px;
      left: 0;
      top: 61px;
      padding: 15px;
    }
  }
  //  tag-pills
  .badge {
    padding: 0.4em 0.7em;
    font-size: 10px;
  }
  // alert page
  .alert-dismissible {
    .btn-close {
      span {
        font-size: 19px;
      }
    }
  }
  // bootstrap notify page
  .notify-alert {
    width: 90%;
  }
  // chat page //
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-header {
          .chat-menu-icons {
            margin-top: 15px;
            padding-left: 6px;
          }
        }
      }
    }
  }
  .default-according {
    .card {
      .card-header {
        .btn-link {
          padding: 12px !important;
          font-size: 15px;
        }
      }
    }
  }
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          .cart-nav {
            .cart-dropdown {
              li {
                .media {
                  img {
                    width: 40px !important;
                    height: 40px !important;
                  }
                  .media-body {
                    h6 {
                      font-size: 12px;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                    p {
                      font-size: 10px !important;
                    }
                  }
                  .cart-quantity {
                    width: 80px;
                    padding: 4px 10px;
                    span {
                      i {
                        font-size: 12px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  .dash-10 {
    max-width: 10%;
    flex: 0 0 10%;
  }
  .dash-76 {
    max-width: 76%;
    flex: 0 0 76%;
  }
  .product-sidebar {
    &.open {
      top: -75px;
    }
  }
  .listings{
    small{
      text-align: right;
    }
  }
  .alert-theme {
    font-size: 12px;
    min-width: 300px;
    button {
      right: 30px !important;
    }
    i {
      display: none;
    }
    span {
      + span {
        + span {
          padding: 15px;
        }
      }
    }
  }
  .user-card{
    .custom-card{
      .card-header{
        img{
          margin-top: -100px;
        }
      }
    }
  }
  .dzu-previewContainer{
    flex-direction: column;
    .dzu-previewStatusContainer{
      margin-top: 10px;
    }
  }
  .product-page-main {
    .pro-group {
      .pro-shop {
        button {
          display: block;
          &:nth-child(n + 2) {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .product-page-main .border-tab.nav-tabs .nav-item {
    width: 100% !important;
  }
  // tab css
  .tabbed-card {
    ul {
      &.border-tab {
        &.nav-tabs {
          .nav-item {
            .nav-link {
              padding: 5px 15px 8px;
            }
          }
        }
      }
    }
  }
  .border-tab {
    &.nav-tabs {
      display: block;
    }
  }
  // chat page //
  .chat-box {
    .chat-menu.show {
      top: 115px;
    }
    .chat-right-aside {
      .chat {
        .chat-header {
          display: block;
          .chat-menu-icons {
            justify-content: flex-start;
            li {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
  .social-app-profile {
    .social-tab {
      .input-group {
        width: 190px;
      }
    }
  }
  .email-wrap {
    .email-body {
      .email-compose {
        .compose-header {
          display: block;
          .btn {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .vertical-timeline-element-content{
    margin-left: 50px;
  }
  .range-slider{
    .row{
      > div{
        > div{
          &:first-child{
            margin: 0 !important;
          }
        }
      }
    }
  }
  .page-wrapper{
    .page-body-wrapper{
      .card{
        .card-body{
          .tab-content{
            .m-t-30{
              margin-top: 12px !important;
            }
          }
        }
      }
    }
  }
  .listings{
    ul.list-group{
      .list-group-item-action{
        img.media-body{
          margin-right: 8px;
        }
      }
    }
  }  
  .dismiss-text {
    .alert-dismissible {
      .btn-close{
        right: 12px;
        top: 15px !important;
        span{
          font-size: 11px;
        }
      }
      p {
        max-width: 106px;
      }
    }
  }
  .tagandpills{
    .icons{
      .badge{
        padding: 4px 5px 5px;
      }
    }
  }  
  .datetime-picker{
    .react-datepicker__month-container, .react-datepicker__time-container{
      width: 100%;
    }
    .react-datepicker__time-container{
      border-left: 1px solid $light-semi-gray;
      .react-datepicker__time{
        .react-datepicker__time-box{
          width: 100%;
        }
      } 
    }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
      right: 15px;
    }
  }
  .btn-toolbar{
    .btn{
      &:last-child{
        margin-left: 0 !important;
      }
    }
  }
  .custom-radio-ml {
    .radio{
      &:first-child{
        margin-bottom: 10px !important;
      }
    }
  }
  .pagination{
    .page-item{
      .page-link{
        padding: 6px 10px;
      }
    }
  }
  .search-page{
    .blog-box{
      .blog-details{
        .blog-social{
          li{
            &:nth-child(n+2){
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  .list-persons{
    .profile-mail{
      .media{
        .media-body{
          margin-top: 10px !important;
        }
      }
    }
  } 
  .user-card{
    .custom-card{
      .profile-details{
        margin-top: 0;
      }
    }
  }  
  .user-profile{
    .profile-post{
      .post-body{
        .post-comment{
          display: block !important;
          li{
            &:last-child{
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .pro-filter-sec{
    .product-sidebar.open{
      min-width: 280px;
      width: 280px;
    }
  } 
  .kanban-item{
    .list{
      li{
        i{
          margin-right: 3px;
        }
      }
    }
  }
  .calender-widget{
    .cal-date{
      width: 90px;
      height: 90px;
    }
  } 
  .custom-profile{
    .card-social{
      .social-link{
        img{
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .weather-widget-two{
    .widget-list{
      .media{
        span{
          font-size: 16px
        }
      }
    }
  }
  .cal-date-widget{
    .react-datepicker{
      padding: 0;
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous{
      left: 0;
    }
    .react-datepicker__navigation.react-datepicker__navigation--next{
      right: 0;
    }
  }
  .page-wrapper{
    .page-header{
      .header-wrapper{
        .nav-right{
          .cart-nav{
            .cart-dropdown{
              li{
                .media{
                  .cart-quantity{
                    width: 68px;
                    padding: 3px 10px;
                  }
                }
              }
            }
          }
          > ul{
            li{
              .profile-media{
                img{
                  width: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  .product-box {
    .modal {
      .modal-header {
        .product-box {
          .product-details {
            .product-qnty {
              fieldset {
                .input-group {
                  width: 45%;
                }
              }
            }
          }
        }
      }
    }
  }
  .icon-hover-bottom {
    .icon-popup {
      display: block;
      text-align: center;
    }
  }
  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-wrapper {
          .attachment {
            ul {
              li {
                img {
                  width: 63px;
                  height: 63px;
                }
              }
            }
          }
        }
      }
      .email-top {
        .dropdown-menu {
          left: -58px;
        }
      }
    }
  }
  .prooduct-details-box {
    .btn-close {
      right: 0;
      top: 5px;
    }
    .btn {
      padding: 4px 8px 2px;
    }
    .media {
      display: block;
      img.media{
        padding: 0;
        border: 0;
      }
      .media-body {
        margin-top: 15px;
      }
    }
  }
  .clockpicker-align-top {
    top: 426px !important;
  }
  .datepicker {
    width: 239px;
  }
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        right: -73px;
      }
    }
  }
  // chart widget page css
  .status-widget {
    svg {
      width: 18px;
      height: 18px;
    }
  }
  // social app page css
  .tabs-scoial {
    &.border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 10px 5px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          right: 6px;
          top: -15px;
          li {
            .social-icon {
              width: 25px;
              height: 25px;
              i {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .social-chat {
    .other-msg {
      margin-left: 15px;
    }
  }
  .social-header {
    h5 {
      span.pull-right {
        display: none;
      }
    }
  }
  .custom-card {
    .dashboard-card {
      padding-top: 0;
    }
    .card-profile {
      img {
        bottom: 8px;
      }
    }
    .card-footer {
      > div {
        h6 {
          font-size: 12px;
        }
      }
    }
  }
  .flot-chart-container {
    height: 230px;
  }
  .tabs-responsive-side {
    max-width: 100%;
  }
  //default dashboard
  .browser-widget {
    .media {
      i {
        font-size: 52px;
      }
      .media-body {
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .bar-chart-widget {
    .bottom-content {
      .num {
        font-size: 17px;
      }
    }
  }
  // serach page //
  .search-page {
    .theme-form {
      .input-group {
        .form-control-plaintext {
          padding-left: 8px;
        }
        .btn {
          padding: 6px 4px;
        }
      }
    }
  }
  .user-status {
    table {
      tbody {
        img {
          height: 40px;
        }
      }
    }
  }
  .xs-width-100 {
    min-width: 100%;
  }
  // according
  .default-according {
    .card {
      .card-header {
        i {
          font-size: 14px;
          top: 24px;
        }
      }
      .btn-link {
        display: block;
        width: 100%;
        padding-left: 17px;
      }
    }
  }
  //rating
  .star-ratings {
    .stars {
      .title {
        line-height: 1;
      }
    }
  }
  // tour page
  .user-profile {
    .hovercard {
      .social-media {
        a {
          padding: 0 12px;
        }
      }
    }
  }
  // blog
  .blog-box {
    .blog-details {
      p {
        line-height: 1.5;
      }
      .blog-social {
        li:last-child {
          padding-left: 0;
        }
      }
    }
    .blog-details-main {
      .blog-social {
        li {
          padding: 0 10px;
        }
      }
    }
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      flex: 0 0 96%;
      max-width: 96%;
      .chat {
        .chat-message {
          width: calc(100% - 20px);
        }
      }
    }
  }
  .chat-box {
    .chat-menu.show {
      top: 120px;
      width: 245px;
    }
  }
  // error 400 page
  .error-wrapper {
    .error-heading {
      .headline {
        font-size: 100px;
      }
    }
  }
  //modal footer
  .modal-footer {
    display: block;
    text-align: center;
    button:first-child {
      margin-bottom: 10px;
    }
  }
  // checkout //
  .order-box {
    .qty {
      li {
        font-size: 14px;
        span {
          font-size: 14px;
        }
      }
    }
    ul {
      li {
        span {
          text-align: right;
        }
        label {
          text-align: right;
          font-size: 14px;
        }
      }
    }
    .sub-total {
      li {
        .count {
          font-size: 14px;
        }
      }
    }
  }
  .checkout {
    .text-end {
      text-align: left !important;
      margin-top: 15px;
    }
  }
}
@media only screen and (max-width: 359.98px) {
  .bar-chart-widget{
    .bottom-content{
      > .row{
        &:first-child{
          margin-bottom: -22px;
        }
      }
    }
  }
  .sticky-header-main {
    .card-header {
      h5 {
        a {
          float: left;
          margin-left: 0 !important;
        }
      }
    }
    .note {
      min-width: 240px;
    }
  }
}
@media only screen and (min-width: 576px) {
  .product-wrapper-grid {
    &.list-view {
      .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .sidebar-wrapper {
    .back-btn {
      display: none;
    }
  }
}
@media only screen and (max-width: 576px) {
  .chat-box {
    .chat-history {
      .call-content {
        min-height: 430px;
      }
    }
  }
  .card {
    .date-range-picker {
      .daterange-card {
        .sub-title {
          font-size: 14px;
        }
      }
    }
  }
}
@media only screen and (max-width: 410px) {
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          .onhover-show-div {
            li.text-center {
              padding: 0;
            }
            &.notification-dropdown {
              &.active {
                li {
                  .media {
                    .notification-right {
                      span {
                        font-size: 10px !important;
                      }
                      a {
                        i {
                          font-size: 10px !important;
                        }
                      }
                    }
                    .notification-img {
                      padding: 8px;
                      img {
                        width: 30px;
                      }
                    }
                  }
                  .media-body {
                    margin-left: 50px !important;
                    h5 {
                      font-size: 13px !important;
                    }
                    p {
                      font-size: 10px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .product-main {
    &.border-start {
      border: none !important;
    }
  }
}
/**=====================
    71. Responsive CSS Ends
==========================**/